export function noValueFormatter(params: any) {
  let originalValue = params.value;
  if (originalValue != 0 && originalValue != null) {
    const hasData = params?.data && (params.data.InvestorID?.length > 1 || params.data.Cash?.length > 1 || params.data.MobileNumber?.length > 1 || params.data.PhoneNumber?.length > 1 || params.data.Email?.length > 1 || params.data.Address1?.length > 1 || params.data.Address2?.length > 1);
    if (originalValue.toLocaleString().includes('expand')) {
      originalValue = hasData ? originalValue.replace(/expand/g, '') + '<span class="ag-icon ag-icon-tree-open"></span>' : originalValue;
    } else {
      originalValue += hasData ? '<span class="ag-icon ag-icon-tree-closed"></span>' : '';
    }
  }
  return originalValue;
}
