<div class="modal fade show custom_popup" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header justify-content-between">
        <h5 class="modal-title">
          Add Debtor/Creditor
        </h5>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal()">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body padding-model form-editing">

        <div class="row">
          <div class="col-lg-12">
            <div class="card w-100">
              <div class="card">
                <form *ngIf="addForm" [formGroup]="addForm" (ngSubmit)="onSubmit()">
                  <div class="row">
                    <div class="width-55 field-groups">
                      <div class="mt-3">
                        <label for="type" class="form-title">
                          Type
                          <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.type }}">
                            <i class="bi bi-question-circle"></i>
                          </span>
                        </label>
                        <div class="input-group">
                          <select placeholder="Select" class="custom-form-select border-radius-100" id="type" formControlName="type">
                            <option value="" disabled selected hidden>Select</option>
                            <option *ngFor="let type of types" [value]="type.name">
                              {{ type.name }}
                            </option>
                          </select>
                        </div>

                      </div>
                      <app-form-error-component [control]="addForm.controls['type']"></app-form-error-component>
                    </div>
                    <div class="width-100 field-groups">
                      <div class="mt-3">
                        <label for="country" class="form-title">
                          Country
                          <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.country }}">
                            <i class="bi bi-question-circle"></i>
                          </span>
                        </label>
                        <div class="input-group">
                          <select class="custom-form-select border-radius-100" id="country" formControlName="country">
                            <option value="" disabled selected hidden>Select</option>
                            <option *ngFor="let country of countryList" [value]="country.name">
                              {{ country.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <app-form-error-component [control]="addForm.controls['country']"></app-form-error-component>
                    </div>
                    <div class="width-55 field-groups">
                      <div class="mt-3">
                        <label for="currency" class="form-title">
                          Ccy
                          <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.currency }}">
                            <i class="bi bi-question-circle"></i>
                          </span>
                        </label>
                        <div class="input-group">
                          <select class="custom-form-select border-radius-100" id="currency" formControlName="currency">
                            <option value="" disabled selected hidden>Select</option>
                            <option *ngFor="let currency of currencyList" [value]="currency.name">
                              {{ currency.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <app-form-error-component [control]="addForm.controls['currency']"></app-form-error-component>
                    </div> 
                    <div  class="width-145 field-groups">
                      <div class="mt-3">
                        <label for="organisation" class="form-title">
                          Organisation
                          <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.organisation }}">
                            <i class="bi bi-question-circle"></i>
                          </span>
                        </label>
                        <div class="input-group">
                          <select class="custom-form-select border-radius-100" id="organisation"
                            formControlName="organisation">
                            <option value="" disabled selected hidden>Select</option>
                            <option *ngFor="let organisation of organisationList" [value]="organisation.name">
                              {{ organisation.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <app-form-error-component [control]="addForm.controls['organisation']"></app-form-error-component>
                    </div>
                    <div class="width-135 field-groups">
                      <div class="mt-3">
                        <label for="tradingName" class="form-title">
                          Trading Name
                          <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.tradingName }}">
                            <i class="bi bi-question-circle"></i>
                          </span>
                        </label>
                        <input type="text" class="form-control sd-width" placeholder="" formControlName="tradingName"
                          id="tradingName" />
                      </div>
                      <app-form-error-component [control]="addForm.controls['tradingName']"></app-form-error-component>
                    </div>

                    <div  class="width-150 field-groups">
                      <div class="mt-3">
                        <label for="companyName" class="form-title">
                          Company Name
                          <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.companyName }}">
                            <i class="bi bi-question-circle"></i>
                          </span>
                        </label>
                        <input type="text" class="form-control sd-width" placeholder="" formControlName="companyName"
                          id="companyName" />
                      </div>
                      <app-form-error-component [control]="addForm.controls['companyName']"></app-form-error-component>
                    </div>

                    <div class="width-208 field-groups">
                      <div class="mt-3">
                        <label for="companyRegistrationNumber" class="form-title">
                          Company Registration Number
                          <span class="tooltip-ng ms-0" placement="bottom"
                            ngbTooltip="{{ tooltipData.companyRegistrationNumber }}">
                            <i class="bi bi-question-circle"></i>
                          </span>
                        </label>
                        <input type="text" class="form-control sd-width" placeholder=""
                          formControlName="companyRegistrationNumber" id="companyRegistrationNumber" />
                      </div>
                      <app-form-error-component
                        [control]="addForm.controls['companyRegistrationNumber']"></app-form-error-component>
                    </div>

                    <div class="width-132 field-groups">
                      <div class="mt-3 mb-3">
                        <label for="previousName" class="form-title">
                          Previous Name
                          <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.previousName }}">
                            <i class="bi bi-question-circle"></i>
                          </span>
                        </label>
                        <input type="text" class="form-control sd-width" placeholder="" formControlName="previousName"
                          id="previousName" />
                      </div>
                      <app-form-error-component [control]="addForm.controls['previousName']"></app-form-error-component>
                    </div>

                    <ng-container *ngIf="addForm.get('type')?.value ==='Creditor'">
                      <div class="card dabetors-creditor-card">
                        <div class="card-header">Bank Details</div>
                      </div>

                      <div  class="width-102 field-groups">
                        <div class="mt-3">
                          <label for="bankId" class="form-title">
                            Bank 
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.bankName }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                            <div class="input-group">
                              <select class="custom-form-select border-radius-100" id="bankId" formControlName="bankId" (change)="onBankChange($event)">
                                <option value="" selected="true">All</option>
                                <option *ngFor="let cc of allBankData" [value]="cc.id">{{ cc.name }}</option>
                            </select>
                            </div>
                        </div>
                        <app-form-error-component [control]="addForm.controls['bankId']"></app-form-error-component>
                      </div>
                      <div  class="width-100 field-groups">
                        <div class="mt-3">
                          <label for="swiftCode" class="form-title">
                             SWIFT BIC
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.swiftCode }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="swiftCode"
                            id="swiftCode" />
                        </div>
                        <app-form-error-component [control]="addForm.controls['swiftCode']"></app-form-error-component>
                      </div>

                      <div class="width-99 mb-3 field-groups">
                        <div class="mt-3">
                          <label for="accountNumber" class="form-title">
                            Account
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.accountNumber }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" onlyNumber class="form-control sd-width numberText" placeholder=""
                            formControlName="accountNumber" id="accountNumber" />
                        </div>
                        <app-form-error-component
                          [control]="addForm.controls['accountNumber']"></app-form-error-component>
                      </div>

                      <div  class="width-190 field-groups">
                        <div class="mt-3">
                          <label for="iban" class="form-title">
                            IBAN
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.iban }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="iban"
                            id="iban" />
                        </div>
                        <app-form-error-component [control]="addForm.controls['iban']"></app-form-error-component>
                      </div>

                    </ng-container>



                    <!-- Section Primary Address -->
                    <ng-container>
                      <div class="card dabetors-creditor-card">
                        <div class="card-header">Primary Address</div>
                      </div>

                      <div  class="width-152 field-groups">
                        <div class="mt-3">
                          <label for="address1" class="form-title">
                            Address 1
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.address }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="address1"
                            id="address1" />
                        </div>
                        <app-form-error-component [control]="addForm.controls['address1']"></app-form-error-component>
                      </div>

                      <div  class="width-152 field-groups">
                        <div class="margin-top-20">
                          <label for=""></label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="address2"
                            id="address2" />
                        </div>
                        <app-form-error-component [control]="addForm.controls['address2']"></app-form-error-component>
                      </div>

                      <div  class="width-87 field-groups">
                        <div class="mt-3">
                          <label for="city" class="form-title">
                            City
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.city }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="city"
                            id="city" />
                        </div>
                        <app-form-error-component [control]="addForm.controls['city']"></app-form-error-component>
                      </div>

                      <div class="width-100 field-groups">
                        <div class="mt-3">
                          <label for="state" class="form-title">
                            County
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.state }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="state"
                            id="state" />
                        </div>
                        <app-form-error-component [control]="addForm.controls['state']"></app-form-error-component>
                      </div>

                      <div  class="width-83 field-groups">
                        <div class="mt-3">
                          <label for="postCode" class="form-title">
                            Post Code
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.postCode }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" onlyNumber class="form-control sd-width numberText" placeholder=""
                            formControlName="postCode" id="postCode" />
                        </div>
                        <app-form-error-component [control]="addForm.controls['postCode']"></app-form-error-component>
                      </div>

                      <div  class="width-131 field-groups">
                        <div class="mt-3">
                          <label for="phoneNumber" class="form-title">
                            Phone Number
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.phoneNumber }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" onlyNumber class="form-control sd-width numberText" placeholder=""
                            formControlName="phoneNumber" id="phoneNumber" />
                        </div>
                        <app-form-error-component
                          [control]="addForm.controls['phoneNumber']"></app-form-error-component>
                      </div>

                      <div class="width-131 field-groups">
                        <div class="mt-3">
                          <label for="otherNumber" class="form-title">
                            Other Number
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.otherNumber }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" onlyNumber class="form-control sd-width numberText" placeholder=""
                            formControlName="otherNumber" id="otherNumber" />
                        </div>
                        <app-form-error-component
                          [control]="addForm.controls['otherNumber']"></app-form-error-component>
                      </div>

                      <div class="width-145 mb-3 field-groups">
                        <div class="mt-3">
                          <label for="email" class="form-title">
                            Email
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.email }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="email"
                            id="email" />
                        </div>
                        <app-form-error-component [control]="addForm.controls['email']"></app-form-error-component>
                      </div>
                    </ng-container>

                    <!-- Section Billing Address  -->
                    <ng-container>
                      <div class="card dabetors-creditor-card">
                        <div class="card-header d-flex">Billing Address
                          <div class="pl-3 form-group checkbox-sec  align-items-center">
                            <input type="checkbox" id="address" [(ngModel)]="isSameAsPrimaryAddress"
                              [ngModelOptions]="{standalone: true}" class="ms-2 address-checkbox" (change)="isPrimaryAddress()" #addressCheckbox />
                            <a href="#"  onclick="return false;" class="ms-2 primary-add" (click)="addressCheckbox.click(); $event.preventDefault();">same as Primary Address</a>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="!isSameAsPrimaryAddress">
                        <div  class="width-152 field-groups">
                          <div class="mt-3">
                            <label for="billingAddress1" class="form-title">
                              Address 1
                              <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.address }}">
                                <i class="bi bi-question-circle"></i>
                              </span>
                            </label>
                            <input type="text" class="form-control sd-width" placeholder=""
                              formControlName="billingAddress1" id="billingAddress1" />
                          </div>
                          <app-form-error-component
                            [control]="addForm.controls['billingAddress1']"></app-form-error-component>
                        </div>

                        <div  class="width-152 field-groups">
                          <div class="margin-top-20">
                            <label for=""></label>
                            <input type="text" class="form-control sd-width" placeholder=""
                              formControlName="billingAddress2" id="billingAddress2" />
                          </div>
                          <app-form-error-component
                            [control]="addForm.controls['billingAddress2']"></app-form-error-component>
                        </div>

                        <div  class="width-87 field-groups">
                          <div class="mt-3">
                            <label for="billingCity" class="form-title">
                              City
                              <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.city }}">
                                <i class="bi bi-question-circle"></i>
                              </span>
                            </label>
                            <input type="text" class="form-control sd-width" placeholder=""
                              formControlName="billingCity" id="billingCity" />
                          </div>
                          <app-form-error-component
                            [control]="addForm.controls['billingCity']"></app-form-error-component>
                        </div>

                        <div class="width-100 field-groups">
                          <div class="mt-3">
                            <label for="billingState" class="form-title">
                              County
                              <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.state }}">
                                <i class="bi bi-question-circle"></i>
                              </span>
                            </label>
                            <input type="text" class="form-control sd-width" placeholder=""
                              formControlName="billingState" id="billingState" />
                          </div>
                          <app-form-error-component
                            [control]="addForm.controls['billingState']"></app-form-error-component>
                        </div>

                        <div  class="width-83 field-groups">
                          <div class="mt-3">
                            <label for="billingPostCode" class="form-title">
                              Post Code
                              <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.postCode }}">
                                <i class="bi bi-question-circle"></i>
                              </span>
                            </label>
                            <input type="text" onlyNumber class="form-control sd-width numberText" placeholder=""
                              formControlName="billingPostCode" id="billingPostCode" />
                          </div>
                          <app-form-error-component
                            [control]="addForm.controls['billingPostCode']"></app-form-error-component>
                        </div>

                        <div class="width-131 field-groups">
                          <div class="mt-3">
                            <label for="billingPhoneNumber" class="form-title">
                              Phone Number
                              <span class="tooltip-ng ms-0" placement="bottom"
                                ngbTooltip="{{ tooltipData.phoneNumber }}">
                                <i class="bi bi-question-circle"></i>
                              </span>
                            </label>
                            <input type="text" onlyNumber class="form-control sd-width numberText" placeholder=""
                              formControlName="billingPhoneNumber" id="billingPhoneNumber" />
                          </div>
                          <app-form-error-component
                            [control]="addForm.controls['billingPhoneNumber']"></app-form-error-component>
                        </div>

                        <div class="width-131 field-groups">
                          <div class="mt-3">
                            <label for="billingOtherNumber" class="form-title">
                              Other Number
                              <span class="tooltip-ng ms-0" placement="bottom"
                                ngbTooltip="{{ tooltipData.otherNumber }}">
                                <i class="bi bi-question-circle"></i>
                              </span>
                            </label>
                            <input type="text" onlyNumber class="form-control sd-width numberText" placeholder=""
                              formControlName="billingOtherNumber" id="billingOtherNumber" />
                          </div>
                          <app-form-error-component
                            [control]="addForm.controls['billingOtherNumber']"></app-form-error-component>
                        </div>

                        <div class="width-145 mb-3 field-groups">
                          <div class="mt-3">
                            <label for="billingEmail" class="form-title">
                              Email
                              <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.email }}">
                                <i class="bi bi-question-circle"></i>
                              </span>
                            </label>
                            <input type="text" class="form-control sd-width" placeholder=""
                              formControlName="billingEmail" id="billingEmail" />
                          </div>
                          <app-form-error-component
                            [control]="addForm.controls['billingEmail']"></app-form-error-component>
                        </div>

                      </ng-container>
                    </ng-container>


                    <!-- Section Account Contact Details -->
                    <ng-container >

                      <div class="card dabetors-creditor-card">
                        <div class="card-header">Account Contact Details</div>
                      </div>

                      <div class="width-110 field-groups">
                        <div class="mt-3">
                          <label for="contactFirstName" class="form-title">
                            First Name
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.contactFirstName }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder=""
                            formControlName="contactFirstName" id="contactFirstName" />
                        </div>
                        <app-form-error-component
                          [control]="addForm.controls['contactFirstName']"></app-form-error-component>
                      </div>

                      <div class="width-110 field-groups">
                        <div class="mt-3">
                          <label for="contactLastName" class="form-title">
                            Last Name
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.contactLastName }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder=""
                            formControlName="contactLastName" id="contactLastName" />
                        </div>
                        <app-form-error-component
                          [control]="addForm.controls['contactLastName']"></app-form-error-component>
                      </div>

                      <div class="width-123 field-groups">
                        <div class="mt-3">
                          <label for="contactPhoneNumber" class="form-title">
                            Phone Number
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.contactPhoneNumber }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" onlyNumber class="form-control sd-width numberText" placeholder=""
                            formControlName="contactPhoneNumber" id="contactPhoneNumber" />
                        </div>
                        <app-form-error-component
                          [control]="addForm.controls['contactPhoneNumber']"></app-form-error-component>
                      </div>

                      <div class="width-145 field-groups">
                        <div class="mt-3">
                          <label for="contactEmail" class="form-title">
                            Email
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.contactEmail }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="contactEmail"
                            id="contactEmail" />
                        </div>
                        <app-form-error-component
                          [control]="addForm.controls['contactEmail']"></app-form-error-component>
                      </div>

                      <div class="width-75 field-groups" style="width: 75px;">
                        <div class="mt-3">
                          <label for="counterPartyYear" class="form-title text-nowrap">
                            Counterparty
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.counterPartyYear }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-group">
                            <select class="custom-form-select border-radius-100" id="counterPartyYear"
                              formControlName="counterPartyYear">
                              <option value="" disabled selected hidden>Select</option>
                              <option *ngFor="let year of years" [value]="year.name">
                                {{ year.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <app-form-error-component
                          [control]="addForm.controls['counterPartyYear']"></app-form-error-component>
                      </div>

                      <div  class="field-groups" style="margin-left: 20px; width: 128px;">
                        <div class="mt-3">
                          <label for="historicValue" class="form-title text-nowrap">
                            Total Historic Value
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.historicValue }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" onlyNumber class="form-control sd-width numberText" placeholder=""
                            formControlName="historicValue" id="historicValue" />
                        </div>
                        <app-form-error-component
                          [control]="addForm.controls['historicValue']"></app-form-error-component>
                      </div>

                      <div class="field-groups" style="width: 145px;">
                        <div class="mt-3">
                          <label for="paymentCycle" class="form-title text-nowrap">
                             Payment Cycle
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.paymentCycle }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="paymentCycle"
                            id="paymentCycle" />
                        </div>
                        <app-form-error-component
                          [control]="addForm.controls['paymentCycle']"></app-form-error-component>
                      </div>

                      <div class="field-groups" style="width: 120px;">
                        <div class="mt-3">
                          <label for="notesIssued" class="form-title text-nowrap">
                            Credit Notes Issued
                            <span class="tooltip-ng ms-0" placement="left" ngbTooltip="{{ tooltipData.notesIssued }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="notesIssued"
                            id="notesIssued" />
                        </div>
                        <app-form-error-component
                          [control]="addForm.controls['notesIssued']"></app-form-error-component>
                      </div>

                      <div class="width-190 field-groups">
                        <div class="mt-3">
                          <label for="invoices" class="form-title text-nowrap">
                            Invoices Predicted Per Annum
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.invoices }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="invoices"
                            id="invoices" />
                        </div>
                        <app-form-error-component [control]="addForm.controls['invoices']"></app-form-error-component>
                      </div>

                      <div class="width-172 mb-3 field-groups">
                        <div class="mt-3">
                          <label for="annualRevenue" class="form-title text-nowrap">
                            Predicted Annual Revenue
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.annualRevenue }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder=""
                            formControlName="annualRevenue" id="annualRevenue" />
                        </div>
                        <app-form-error-component
                          [control]="addForm.controls['annualRevenue']"></app-form-error-component>
                      </div>
                    </ng-container>

                      <!-- Section Experience -->
                    <ng-container  *ngIf="addForm.get('type')?.value ==='Debtor'" >

                      <div class="card dabetors-creditor-card">
                        <div class="card-header">Experience</div>
                      </div>
  
                      <div class="col-12 mt-3 mb-3 form-group file-upload text-center">
                        <label for="image_file" class="me-2">Experience File: </label>
                        <input type="file" id="image_file"  #fileUploader (change)="fileChange($event)" accept=".pdf,.doc,.docx,.xls,.xlsx">
    
                        <a target="_blank" class="download-link-a d-block" (click)="downloadTemplate()">
                          Download XLSX Template
                         </a>
                      </div>
                      </ng-container>
                  </div>

               
                
                  <div class="col-12 bottom-fixed">
                    <div class="d-flex justify-content-center mt-3">
                      <button [disabled]="addForm.invalid" type="submit"
                        class="btn btn-light me-3 btn-custom-footer save_btn">
                        Save
                      </button>
                      <button type="button" class="btn btn-light btn-custom-footer cancel_btn" data-dismiss="modal"
                        (click)="closeModal()">
                        Cancel
                      </button>
                    </div>
                  </div>

                </form>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>