<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div
  id="main-wrapper"
  [ngClass]="{ 'show-sidebar': showMobileMenu }"
  dir="ltr"
  data-theme="light"
  data-layout="vertical"
  [attr.data-sidebartype]="sidebartype"
  data-sidebar-position="absolute"
  data-header-position="absolute"
  data-boxed-layout="full"
>
  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in topbar.scss -->
  <!-- ============================================================== -->
  <header class="topbar" data-navbarbg="skin1">
    <nav class="d-flex top-navbar navbar-expand-lg navbar-dark" (click)="getUserDetails()">
      <div
        class="navbar-header"
        [ngClass]="expandLogo ? 'expand-logo' : ''"
        data-logobg="skin6"
      >
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a
          (click)="showMobileMenu = !showMobileMenu"
          class="nav-toggler waves-effect waves-light d-block d-lg-none"
          href="javascript:void(0)"
        >
          <i [ngClass]="showMobileMenu ? 'bi bi-text-left fs-6' : 'bi bi-text-left fs-6'"></i>
        </a>
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <a class="navbar-brand" href="/">
          <!-- Logo icon -->
          
          <!--End Logo icon -->
          <!-- Logo text -->
          <span class="logo-text">
            <!-- Light Logo text -->
            <img
              src="assets/images/logos/logo-text.png"
              class="dark-logo"
              alt="homepage"
            />
          </span>
          <span class="logo-text-mini">
            <!-- Light Logo text -->
            <img
              src="assets/images/logos/logo-icon.png"
              class="dark-logo"
              alt="homepage"
            />
          </span>
        </a>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Toggle which is visible on mobile only -->
        <!-- ============================================================== -->
        <a
          class="topbartoggler d-block d-lg-none waves-effect waves-light right_hamburger"
          href="javascript:void(0)"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
        >
          <i class="bi bi-text-right fs-6"></i>
        </a>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div
        class="navbar-collapse collapse"
        id="navbarSupportedContent"
        data-navbarbg="skin1"
        [ngbCollapse]="!isCollapsed"
      >
        <app-navigation
          (toggleSidebar)="toggleSidebarType()"
          class="w-100"
        ></app-navigation>
      </div>
    </nav>
  </header>
  <!-- ============================================================== -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <aside
    class="left-sidebar"
    data-sidebarbg="skin6"
    (mouseover)="Logo()"
    (mouseout)="Logo()"
  >
      <app-sidebar></app-sidebar>
  <div class="user-menu-sidebar">

    <!-- <i class="bi bi-question-circle"></i> -->
    <!-- Default dropup button -->

    <div ngbDropdown>
      <button type="button" class="btn btn-outline-primary btn-leftside" id="dropdownConfig" ngbDropdownToggle><img src="./assets/images/icon/person.svg" alt=""> <span title="{{userName}}">{{userName}}</span></button>
      <div ngbDropdownMenu aria-labelledby="dropdownConfig">
        <button ngbDropdownItem (click)="onLogoutClick()">Logout</button>
      </div>
    </div>

    
  </div>
      
  </aside>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <!-- <footer class="footer text-center">
    </footer> -->
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
</div>
