export const TooltipData_OpenTrade = {
  creditor: 'Upload ETR invoice for',
  originator: 'Select b-ETR',
  unscheduledEtr: '',
  paymentDiscount: 'Payment Discount',
  ccy: 'Currency',
  faceValue: 'Face Value',
  remainingValue: 'Remaining Value',
  vatIncluded: '	VAT included',
  invoiceDate: 'Invoice Date',
  expectedDate: '	Expected Date ',
  pod: '	PoD',
  attachPod: 'Attach PoD',
  invoiceNo: 'Invoice No',
  attachInvoice: 'Attach Invoice',
  loanTerms: 'c-ETR Loan Terms',
  debtor:'Name',
  paymentTerms:'Payment Terms',
  totalFaceValueofETR : 'Total Face Value of ETR',
  numberofetr:'Number of ETR',
  months:'Revolving Offer Month',
  description:'Description',
  supportingDocument:'Attach Supporting Document',
  monthlyDiscount:'Monthly Discount Percentage',
  allowBuyOut:'Allow Buy Out',
  buyOutDiscount:'Buy Out Discount Percentage',
  friendlyName:'Friendly Name',
  sellRate: 'c-ETR Sell Rate Percentage',
  bEtr: 'b-ETR',
  poDate:  'PO Date',
  poNumber: 'PO No',
  pofile:  'PO file',
  revolvingETR:'Upload Revolving ETR invoice for',
  deliverNoA:'Deliver NoA to'

};