export const COUNTRY_LIST: Array<any> = [
  {
    id: 1,
    name: 'Afghanistan',
    countryCode: 'AF',
  },
  {
    id: 2,
    name: 'Albania',
    countryCode: 'AL',
  },
  {
    id: 3,
    name: 'Algeria',
    countryCode: 'DZ',
  },
  {
    id: 4,
    name: 'Andorra',
    countryCode: 'AD',
  },
  {
    id: 5,
    name: 'Angola',
    countryCode: 'AO',
  },
  {
    id: 6,
    name: 'Antigua and Barbuda',
    countryCode: 'AG',
  },
  {
    id: 7,
    name: 'Argentina',
    countryCode: 'AR',
  },
  {
    id: 8,
    name: 'Armenia',
    countryCode: 'AM',
  },
  {
    id: 9,
    name: 'Australia',
    countryCode: 'AU',
  },
  {
    id: 10,
    name: 'Austria',
    countryCode: 'AT',
  },
  {
    id: 11,
    name: 'Azerbaijan',
    countryCode: 'AZ',
  },
  {
    id: 12,
    name: 'Bahamas',
    countryCode: 'BS',
  },
  {
    id: 13,
    name: 'Bahrain',
    countryCode: 'BH',
  },
  {
    id: 14,
    name: 'Bangladesh',
    countryCode: 'BD',
  },
  {
    id: 15,
    name: 'Barbados',
    countryCode: 'BB',
  },
  {
    id: 16,
    name: 'Belarus',
    countryCode: 'BY',
  },
  {
    id: 17,
    name: 'Belgium',
    countryCode: 'BE',
  },
  {
    id: 18,
    name: 'Belize',
    countryCode: 'BZ',
  },
  {
    id: 19,
    name: 'Benin',
    countryCode: 'BJ',
  },
  {
    id: 20,
    name: 'Bhutan',
    countryCode: 'BT',
  },
  {
    id: 21,
    name: 'Bolivia',
    countryCode: 'BO',
  },
  {
    id: 22,
    name: 'Bosnia and Herzegovina',
    countryCode: 'BA',
  },
  {
    id: 23,
    name: 'Botswana',
    countryCode: 'BW',
  },
  {
    id: 24,
    name: 'Brazil',
    countryCode: 'BR',
  },
  {
    id: 25,
    name: 'Brunei',
    countryCode: 'BN',
  },
  {
    id: 26,
    name: 'Bulgaria',
    countryCode: 'BG',
  },
  {
    id: 27,
    name: 'Burkina Faso',
    countryCode: 'BF',
  },
  {
    id: 28,
    name: 'Burundi',
    countryCode: 'BI',
  },
  {
    id: 29,
    name: "Côte d'Ivoire",
    countryCode: 'CI',
  },
  {
    id: 30,
    name: 'Cabo Verde',
    countryCode: 'CV',
    population: 556982,
  },
  {
    id: 31,
    name: 'Cambodia',
    countryCode: 'KH',
  },
  {
    id: 32,
    name: 'Cameroon',
    countryCode: 'CM',
  },
  {
    id: 33,
    name: 'Canada',
    countryCode: 'CA',
  },
  {
    id: 34,
    name: 'Central African Republic',
    countryCode: 'CF',
  },
  {
    id: 35,
    name: 'Chad',
    countryCode: 'TD',
  },
  {
    id: 36,
    name: 'Chile',
    countryCode: 'CL',
  },
  {
    id: 37,
    name: 'China',
    countryCode: 'CN',
  },
  {
    id: 38,
    name: 'Colombia',
    countryCode: 'CO',
  },
  {
    id: 39,
    name: 'Comoros',
    countryCode: 'KM',
  },
  {
    id: 40,
    name: 'Congo (Congo-Brazzaville)',
    countryCode: 'CG',
  },
  {
    id: 41,
    name: 'Costa Rica',
    countryCode: 'CR',
  },
  {
    id: 42,
    name: 'Croatia',
    countryCode: 'HR',
  },
  {
    id: 43,
    name: 'Cuba',
    countryCode: 'CU',
  },
  {
    id: 44,
    name: 'Cyprus',
    countryCode: 'CY',
  },
  {
    id: 45,
    name: 'Czechia (Czech Republic)',
    countryCode: 'CZ',
  },
  {
    id: 46,
    name: 'Democratic Republic of the Congo',
    countryCode: 'CD',
  },
  {
    id: 47,
    name: 'Denmark',
    countryCode: 'DK',
  },
  {
    id: 48,
    name: 'Djibouti',
    countryCode: 'DJ',
  },
  {
    id: 49,
    name: 'Dominica',
    countryCode: 'DM',
  },
  {
    id: 50,
    name: 'Dominican Republic',
    countryCode: 'DO',
  },
  {
    id: 51,
    name: 'Ecuador',
    countryCode: 'EC',
  },
  {
    id: 52,
    name: 'Egypt',
    countryCode: 'EG',
  },
  {
    id: 53,
    name: 'El Salvador',
    countryCode: 'SV',
  },
  {
    id: 54,
    name: 'Equatorial Guinea',
    countryCode: 'GQ',
  },
  {
    id: 55,
    name: 'Eritrea',
    countryCode: 'ER',
  },
  {
    id: 56,
    name: 'Estonia',
    countryCode: 'EE',
  },
  {
    id: 57,
    name: 'Eswatini (fmr. "Swaziland")',
    countryCode: 'SZ',
  },
  {
    id: 58,
    name: 'Ethiopia',
    countryCode: 'ET',
  },
  {
    id: 59,
    name: 'Fiji',
    countryCode: 'FJ',
  },
  {
    id: 60,
    name: 'Finland',
    countryCode: 'FI',
  },
  {
    id: 61,
    name: 'France',
    countryCode: 'FR',
  },
  {
    id: 62,
    name: 'Gabon',
    countryCode: 'GA',
  },
  {
    id: 63,
    name: 'Gambia',
    countryCode: 'GM',
  },
  {
    id: 65,
    name: 'Georgia',
    countryCode: 'GE',
  },
  {
    id: 66,
    name: 'Germany',
    countryCode: 'DE',
  },
  {
    id: 67,
    name: 'Ghana',
    countryCode: 'GH',
  },
  {
    id: 68,
    name: 'Greece',
    countryCode: 'GR',
  },
  {
    id: 69,
    name: 'Grenada',
    countryCode: 'GD',
  },
  {
    id: 70,
    name: 'Guatemala',
    countryCode: 'GT',
  },
  {
    id: 71,
    name: 'Guinea',
    countryCode: 'GN',
  },
  {
    id: 72,
    name: 'Guinea-Bissau',
    countryCode: 'GW',
  },
  {
    id: 73,
    name: 'Guyana',
    countryCode: 'GY',
  },
  {
    id: 74,
    name: 'Haiti',
    countryCode: 'HT',
  },
  {
    id: 75,
    name: 'Holy See',
    countryCode: 'VA',
  },
  {
    id: 76,
    name: 'Honduras',
    countryCode: 'HN',
  },
  {
    id: 77,
    name: 'Hungary',
    countryCode: 'HU',
  },
  {
    id: 78,
    name: 'Iceland',
    countryCode: 'IS',
  },
  {
    id: 79,
    name: 'India',
    countryCode: 'IN',
  },
  {
    id: 80,
    name: 'Indonesia',
    countryCode: 'ID',
  },
  {
    id: 81,
    name: 'Iran',
    countryCode: 'IR',
  },
  {
    id: 82,
    name: 'Iraq',
    countryCode: 'IQ',
  },
  {
    id: 83,
    name: 'Ireland',
    countryCode: 'IE',
  },
  {
    id: 84,
    name: 'Israel',
    countryCode: 'IL',
  },
  {
    id: 85,
    name: 'Italy',
    countryCode: 'IT',
  },
  {
    id: 86,
    name: 'Jamaica',
    countryCode: 'JM',
  },
  {
    id: 87,
    name: 'Japan',
    countryCode: 'JP',
  },
  {
    id: 88,
    name: 'Jordan',
    countryCode: 'JO',
  },
  {
    id: 89,
    name: 'Kazakhstan',
    countryCode: 'KZ',
  },
  {
    id: 90,
    name: 'Kenya',
    countryCode: 'KE',
  },
  {
    id: 91,
    name: 'Kiribati',
    countryCode: 'KI',
  },
  {
    id: 92,
    name: 'Kuwait',
    countryCode: 'KW',
  },
  {
    id: 93,
    name: 'Kyrgyzstan',
    countryCode: 'KG',
  },
  {
    id: 94,
    name: 'Laos',
    countryCode: 'LA',
  },
  {
    id: 95,
    name: 'Latvia',
    countryCode: 'LV',
  },
  {
    id: 96,
    name: 'Lebanon',
    countryCode: 'LB',
  },
  {
    id: 97,
    name: 'Lesotho',
    countryCode: 'LS',
  },
  {
    id: 98,
    name: 'Liberia',
    countryCode: 'LR',
  },
  {
    id: 99,
    name: 'Libya',
    countryCode: 'LY',
  },
  {
    id: 100,
    name: 'Liechtenstein',
    countryCode: 'LI',
  },
  {
    id: 101,
    name: 'Lithuania',
    countryCode: 'LT',
  },
  {
    id: 102,
    name: 'Luxembourg',
    countryCode: 'LU',
  },
  {
    id: 103,
    name: 'Madagascar',
    countryCode: 'MG',
  },
  {
    id: 104,
    name: 'Malawi',
    countryCode: 'MW',
  },
  {
    id: 105,
    name: 'Malaysia',
    countryCode: 'MY',
  },
  {
    id: 106,
    name: 'Maldives',
    countryCode: 'MV',
  },
  {
    id: 107,
    name: 'Mali',
    countryCode: 'ML',
  },
  {
    id: 108,
    name: 'Malta',
    countryCode: 'MT',
  },
  {
    id: 109,
    name: 'Marshall Islands',
    countryCode: 'MH',
  },
  {
    id: 110,
    name: 'Mauritania',
    countryCode: 'MR',
  },
  {
    id: 111,
    name: 'Mauritius',
    countryCode: 'MU',
  },
  {
    id: 112,
    name: 'Mexico',
    countryCode: 'MX',
  },
  {
    id: 113,
    name: 'Micronesia',
    countryCode: 'FM',
  },
  {
    id: 114,
    name: 'Moldova',
    countryCode: 'MD',
  },
  {
    id: 115,
    name: 'Monaco',
    countryCode: 'MC',
  },
  {
    id: 116,
    name: 'Mongolia',
    countryCode: 'MN',
  },
  {
    id: 117,
    name: 'Montenegro',
    countryCode: 'ME',
  },
  {
    id: 118,
    name: 'Morocco',
    countryCode: 'MA',
  },
  {
    id: 119,
    name: 'Mozambique',
    countryCode: 'MZ',
  },
  {
    id: 120,
    name: 'Myanmar',
    countryCode: 'MM',
  },
  {
    id: 121,
    name: 'Namibia',
    countryCode: 'NA',
  },
  {
    id: 122,
    name: 'Nauru',
    countryCode: 'NR',
  },
  {
    id: 123,
    name: 'Nepal',
    countryCode: 'NP',
  },
  {
    id: 124,
    name: 'Netherlands',
    countryCode: 'NL',
  },
  {
    id: 125,
    name: 'New Zealand',
    countryCode: 'NZ',
  },
  {
    id: 127,
    name: 'Nicaragua',
    countryCode: 'NI',
  },
  {
    id: 128,
    name: 'Niger',
    countryCode: 'NE',
  },
  {
    id: 129,
    name: 'Nigeria',
    countryCode: 'NG',
  },
  {
    id: 130,
    name: 'North Korea',
    countryCode: 'KP',
  },
  {
    id: 131,
    name: 'North Macedonia',
    countryCode: 'MK',
  },
  {
    id: 132,
    name: 'Norway',
    countryCode: 'NO',
  },
  {
    id: 133,
    name: 'Oman',
    countryCode: 'OM',
  },
  {
    id: 134,
    name: 'Pakistan',
    countryCode: 'PK',
  },
  {
    id: 135,
    name: 'Palau',
    countryCode: 'PW',
  },
  {
    id: 136,
    name: 'Palestine State',
    countryCode: 'PS',
  },
  {
    id: 137,
    name: 'Panama',
    countryCode: 'PA',
  },
  {
    id: 138,
    name: 'Papua New Guinea',
    countryCode: 'PG',
  },
  {
    id: 139,
    name: 'Paraguay',
    countryCode: 'PY',
  },
  {
    id: 140,
    name: 'Peru',
    countryCode: 'PE',
  },
  {
    id: 141,
    name: 'Philippines',
    countryCode: 'PH',
  },
  {
    id: 142,
    name: 'Poland',
    countryCode: 'PL',
  },
  {
    id: 143,
    name: 'Portugal',
    countryCode: 'PT',
  },
  {
    id: 144,
    name: 'Qatar',
    countryCode: 'QA',
  },
  {
    id: 145,
    name: 'Romania',
    countryCode: 'RO',
  },
  {
    id: 146,
    name: 'Russia',
    countryCode: 'RU',
  },
  {
    id: 147,
    name: 'Rwanda',
    countryCode: 'RW',
  },
  {
    id: 148,
    name: 'Saint Kitts and Nevis',
    countryCode: 'KN',
  },
  {
    id: 149,
    name: 'Saint Lucia',
    countryCode: 'LC',
  },
  {
    id: 150,
    name: 'Saint Vincent and the Grenadines',
    countryCode: 'VC',
  },
  {
    id: 151,
    name: 'Samoa',
    countryCode: 'WS',
  },
  {
    id: 152,
    name: 'San Marino',
    countryCode: 'SM',
  },
  {
    id: 153,
    name: 'Sao Tome and Principe',
    countryCode: 'ST',
  },
  {
    id: 154,
    name: 'Saudi Arabia',
    countryCode: 'SA',
  },
  {
    id: 155,
    name: 'Senegal',
    countryCode: 'SN',
  },
  {
    id: 156,
    name: 'Serbia',
    countryCode: 'RS',
  },
  {
    id: 157,
    name: 'Seychelles',
    countryCode: 'SC',
  },
  {
    id: 158,
    name: 'Sierra Leone',
    countryCode: 'SL',
  },
  {
    id: 159,
    name: 'Singapore',
    countryCode: 'SG',
  },
  {
    id: 160,
    name: 'Slovakia',
    countryCode: 'SK',
  },
  {
    id: 161,
    name: 'Slovenia',
    countryCode: 'SI',
  },
  {
    id: 162,
    name: 'Solomon Islands',
    countryCode: 'SB',
  },
  {
    id: 163,
    name: 'Somalia',
    countryCode: 'SO',
  },
  {
    id: 164,
    name: 'South Africa',
    countryCode: 'ZA',
  },
  {
    id: 165,
    name: 'South Sudan',
    countryCode: 'SS',
  },
  {
    id: 166,
    name: 'Spain',
    countryCode: 'ES',
  },
  {
    id: 167,
    name: 'Sri Lanka',
    countryCode: 'LK',
  },
  {
    id: 168,
    name: 'Sudan',
    countryCode: 'SD',
  },
  {
    id: 169,
    name: 'Suriname',
    countryCode: 'SR',
  },
  {
    id: 170,
    name: 'Sweden',
    countryCode: 'SE',
  },
  {
    id: 171,
    name: 'Switzerland',
    countryCode: 'CH',
  },
  {
    id: 172,
    name: 'Syria',
    countryCode: 'SY',
  },
  {
    id: 173,
    name: 'Taiwan',
    countryCode: 'TW',
  },
  {
    id: 174,
    name: 'Tajikistan',
    countryCode: 'TJ',
  },
  {
    id: 175,
    name: 'Tanzania',
    countryCode: 'TZ',
  },
  {
    id: 176,
    name: 'Thailand',
    countryCode: 'TH',
  },
  {
    id: 177,
    name: 'Timor-Leste',
    countryCode: 'TL',
  },
  {
    id: 178,
    name: 'Togo',
    countryCode: 'TG',
  },
  {
    id: 179,
    name: 'Tonga',
    countryCode: 'TO',
  },
  {
    id: 180,
    name: 'Trinidad and Tobago',
    countryCode: 'TT',
  },
  {
    id: 181,
    name: 'Tunisia',
    countryCode: 'TN',
  },
  {
    id: 182,
    name: 'Turkey',
    countryCode: 'TR',
  },
  {
    id: 183,
    name: 'Turkmenistan',
    countryCode: 'TM',
  },
  {
    id: 184,
    name: 'Tuvalu',
    countryCode: 'TV',
  },
  {
    id: 185,
    name: 'Uganda',
    countryCode: 'UG',
  },
  {
    id: 186,
    name: 'Ukraine',
    countryCode: 'UA',
  },
  {
    id: 187,
    name: 'United Arab Emirates',
    countryCode: 'AE',
  },
  {
    id: 188,
    name: 'United Kingdom',
    countryCode: 'GB',
  },
  {
    id: 189,
    name: 'United States of America',
    countryCode: 'US',
  },
  {
    id: 190,
    name: 'Uruguay',
    countryCode: 'UY',
  },
  {
    id: 191,
    name: 'Uzbekistan',
    countryCode: 'UZ',
  },
  {
    id: 192,
    name: 'Vanuatu',
    countryCode: 'VU',
  },
  {
    id: 193,
    name: 'Venezuela',
    countryCode: 'VE',
  },
  {
    id: 194,
    name: 'Vietnam',
    countryCode: 'VN',
  },
  {
    id: 195,
    name: 'Yemen',
    countryCode: 'YE',
  },
  {
    id: 196,
    name: 'Zambia',
    countryCode: 'ZM',
  },
  {
    id: 197,
    name: 'Zimbabwe',
    countryCode: 'ZW',
  },
];

