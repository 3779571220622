import { Component, OnInit, ViewChild, inject, Input, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddUserComponent } from 'src/app/profile/add-user/add-user.component';
import { AddDebtorCreditorComponent } from 'src/app/debtor-creditor/add-debtor-creditor/add-debtor-creditor.component';
import { TransactionUpdateComponent } from 'src/app/transactions/transaction-update/transaction-update.component';

@Component({
  selector: 'ngbd-modal-content',
  standalone: true,
  template: `
    <div class="modal-header" role="dialog" aria-modal="true">
      <h4 class="modal-title">Hi there!</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <app-uploads></app-uploads>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NgbdModalContent {
  activeModal = inject(NgbActiveModal);
  @Input() name: string | undefined;
}
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './sidebar.component.html',
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SidebarComponent implements OnInit {
  @ViewChild('projctModal') projctModal: any;
  showMenu = '';
  showSubMenu = '';
  isClassAdded = false;
  public sidebarnavItems: RouteInfo[] = [];
  popupTitles: string[] = ['Add New User','Add Debtor/Creditor','Transaction Update'];

  constructor(
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
  }

  // this is for the open close
  addExpandClass(element: string, _expandclass:string) {
    this.removeSideBarClass(_expandclass);
    if (element === this.showMenu) {
      this.showMenu = '0';
      this.showSubMenu = '0';
    } else {
      this.showMenu = element;
      this.showSubMenu = '0';
    }
  }

  addExpandSubClass(element: string, _expandclass:string) {
    this.removeSideBarClass(_expandclass);
    this.callModal(element, _expandclass);
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  openModal() {
    this.projctModal.show();
  }

  callModal(title: string,_expandclass:string) {
    this.removeSideBarClass(_expandclass);
    switch (title) {
        case 'Add New User':
          this.modalService.open(AddUserComponent, { backdrop: true });
          break;
        case 'Add Debtor/Creditor':
          this.modalService.open(AddDebtorCreditorComponent, { backdrop: true });
          break;
        case 'Transaction Update':
          this.modalService.open(TransactionUpdateComponent, { backdrop: true });
          break;
    }
  }

  removeSideBarClass(_expandclass: string) {
    if (_expandclass !== 'collapse') {
      const element = document.getElementById('main-wrapper') as Element;
      element.classList.remove('show-sidebar');
    }

  }

  shouldDisplayItem(sidebarnavItem: any): boolean {
    return  !sidebarnavItem.extralink && this.popupTitles.includes(sidebarnavItem.title);
  }
}

