import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
@Injectable({ providedIn: 'root' })
export class TransactionFormProvider {
  transactionUpdateForm: FormGroup;
  constructor(private fb: FormBuilder) {}

  initForm(data?: any): FormGroup {
    return (this.transactionUpdateForm = this.fb.group({
      originatorId: [data?.originatorId],
      transactionType: [data?.transactionType||''],
      credebtor: [data?.credebtor||''],
      date: [data?.date],
      amount: [data?.amount],
      number: [data?.number],
      lastName: [data?.lastName],
      transactionFile: [data?.transactionFile],
      comments : [data?.comments ],
      payment: [data?.payment||'' ],
      specificDeductible: [data?.specificDeductible ],
      trailingBalance: [data?.trailingBalance ],
      specificDeductibleAmount: [data?.specificDeductibleAmount ],
      trailingBalanceAmount: [data?.trailingBalanceAmount ],
      reference: [data?.reference ],

     
    }));
  }
  private parseDate(param: string) {
    return {
      year: parseInt(moment(param).format('YYYY'), 10),
      day: parseInt(moment(param).format('DD'), 10),
      month: parseInt(moment(param).format('M'), 10),
    };
  }
}
