import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'clientApp';
  returnUrl!: string;
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  routerSubscription: Subscription;
  timerStartSubscription: Subscription | null = null;
  timeoutSubscription: Subscription | null = null;
  constructor(
    private userIdle: UserIdleService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/login') {
          this.stopUserIdle();
        } else {
          this.startUserIdle();
        }
      }
    });
    this.isIframe = window !== window.parent && !window.opener;
  }

  startUserIdle(): void {
    this.userIdle.startWatching();
    this.timerStartSubscription = this.userIdle.onTimerStart().subscribe((count) => {});
    this.timeoutSubscription = this.userIdle.onTimeout().subscribe(() => {
      if (!this.isRememberMe()) {
        this.logout();
      }
    });
  }

  stopUserIdle(): void {
    this.userIdle.stopWatching();
    this.timerStartSubscription?.unsubscribe();
    this.timerStartSubscription = null;
    this.timeoutSubscription?.unsubscribe();
    this.timeoutSubscription = null;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  isRememberMe(): boolean {
    const rememberMeValue = localStorage.getItem('rememberMe');
    if (rememberMeValue !== null) {
      return JSON.parse(rememberMeValue);
    }
    return false;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
