import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  @Input() currentPageIndex: number = 0;
  @Input() paginationPageSize!: number;
  @Input() totalRecordsDisplay!: string;
  @Input() totalPageString!: string;
  @Input() totalPage!: number;

  @Output() pageChange = new EventEmitter();
}
