import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getFormData } from 'src/app/shared/helpers/utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WithdrawFund } from 'src/app/shared/interfaces/withdraw-fund-response.interface';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { UserDetailsFormProvider } from '../userDetail-form-provider';
import { ORIGINATOR_ID } from 'src/app/shared/enums/common.enum';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent {
  userForm: FormGroup;
  // todo need remove when can get from backend side
  tooltipData = {
    firstName:'First Name',
    lastName:'Last Name',
    organisationName:'Organisation Name',
    email:' Email',
  };
  isSubmitted = false;
  userData: WithdrawFund;
  originatorId = ORIGINATOR_ID;
  constructor(
    private userFormProvider: UserDetailsFormProvider,
    public activeModal: NgbActiveModal,
    private http: HttpService,
  ) {}

  ngOnInit(): void {
    this.userForm = this.userFormProvider.initFormAddNewUser(this.userData);
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.userForm.invalid) {
      return;
    }
    let rawData = this.userForm.value;
    rawData = getFormData(rawData);
    rawData.originatorId = this.originatorId;
    // this.doCreate(rawData);
  }

  onSelect(event: any): void {
    this.isSubmitted = false;
  }

  doCreate(data: any): void {
    this.http.postData('adduser', data).subscribe({
      next: (response: any) => {
        this.closeModal();
        this.userForm.reset();
        Swal.fire({
          icon: 'success',
          text: 'User successfully added.',
          showConfirmButton: false,
          timer: 5000,
        });
      },
      error: (error) => {
        Swal.fire({
          icon: 'error',
          text: error.error.details || error.error.message,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
      },
    });
  }



  closeModal() {
    this.activeModal.dismiss();
  }
}
