<div class="modal fade show custom_popup" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document"  
  [ngClass]="{
    'open-a-trade-class': pageTitle === 'Open A Trade', 
    'etr-invoice-upload-class': pageTitle === 'ETR Invoice Upload',
    'revolving-etr-invoice-upload': pageTitle === 'Revolving ETR Invoice Upload',
    'open-b-etr-trade': pageTitle === 'Open b-ETR Trade',
    'upload-po-class': pageTitle === 'Upload PO',
  }">
    <div class="modal-content">
      <div class="modal-header justify-content-between">
        <h5 class="modal-title">
         {{pageTitle}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal()">
          <span>&times;</span>
        </button>
      </div>

      <!-- ETR Invoice Upload      -->
      <ng-container *ngIf="pageTitle==='ETR Invoice Upload'">
        <div  class="modal-body padding-model form-editing">
          <div class="row">
            <div class="col-lg-12">
              <div class="card w-100 mb-0">
                <div class="card  mb-0">
                  <form *ngIf="addForm" [formGroup]="addForm" (ngSubmit)="onSubmit()">
                    <div class="row">
  
                      <div class="width-155 field-groups">
                        <div class="mt-3">
                          <label for="creditor" class="form-title text-nowrap">
                            ETR invoice for
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.creditor }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                        
                          <ng-select
                          class="custom"
                          id="creditor"
                          [style.flex]="1"
                          [items]="creditorList"
                          bindLabel="name"
                          bindValue="id"
                          placeholder="Select Creditor"
                          formControlName="creditor"
                          (change)="onCreditorChange($event)"
                          [searchFn]="customSearch"
                        >
                          <ng-template ng-label-tmp let-item="item">
                            {{ item.name }}
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item">
                            <span [title]="item.name" tooltip="item.name">{{ item.id }} - {{ item.name }}</span>
                          </ng-template>
                        </ng-select> 

                        </div>
                      </div>

                    

  
                      <div class="width-110 field-groups">
                        <div class="mt-3">
                          <label for="originator" class="form-title text-nowrap">
                            Select b-ETR
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.originator }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-group">
                            <ng-select
                            class="custom"
                            id="originator"
                            [style.flex]="1"
                            [items]="originatorList"
                            bindLabel="name"
                            bindValue="id"
                            placeholder="Select b-ETR"
                            formControlName="originator"
                            (change)="onOriginatorChange($event,'etr_invoice_upload')"
                            [searchFn]="customSearch"
                          >
                            <ng-template ng-label-tmp let-item="item">
                              {{ item.originatorId }}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                              <span [title]="item.originatorId" tooltip="item.name">{{ item.originatorId }} - {{item.date}} - Available: {{item.ccy}}
                                {{item.value}}</span>
                            </ng-template>
                          </ng-select> 
                          </div>
                        </div>
                      </div>
  
                      <div class="field-groups width-77" >
                        <div class="mt-3">
                          <label for="paymentDiscount" class="form-title text-nowrap">
                            Payment
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.paymentDiscount }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-container input-container d-flex gap-1 align-items-center">
                            <input id="paymentDiscount" type="text" class="form-control sd-width inputNumberText"
                              placeholder="" formControlName="paymentDiscount" maxlength="10" [readOnly]="true" />
                            <span>%</span>
                          </div>
                        </div>
                      </div>

                      <div class="width-50 field-groups" style="margin-left: 1px;">
                        <div class="mt-3">
                          <label for="ccy" class="form-title text-nowrap">
                            Ccy
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.ccy }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <span id="ccy" class="d-block text-nowrap">{{addForm.get('ccy')?.value }}</span>
                        </div>
                      </div>

                  

                      <div class="width-85 field-groups">
                        <div class="mt-3">
                          <label for="faceValue" class="form-title text-nowrap">
                            Face Value
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.faceValue }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" [appOnlyNumber]="true"
                            class="form-control text-left ft-width inputNumberText" maxlength="10" placeholder=""
                            formControlName="faceValue" id="faceValue" (input)="onFaceValueChange('open_a_trade')" />
  
                        </div>
                      </div>
  
                      <div class="width-120 field-groups">
                        <div class="mt-3">
                          <label for="remainingValue" class="form-title text-nowrap">
                            Remaining Value
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.remainingValue }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <span id="remainingValue" class="d-block text-nowrap text-end">{{
                            addForm.get('remainingValue')?.value
                            }}</span>
                        </div>
                      </div>
                    
  
                      <div class="width-134 field-groups">
                        <div class="mt-3">
                          <label for="invoiceDate" class="form-title text-nowrap"> Invoice Date
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.invoiceDate }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-group">
                            <input id="invoiceDate" class="form-control" placeholder="YYYY-MM-DD" name="d1"
                              formControlName="invoiceDate" ngbDatepicker #d1="ngbDatepicker"  />
                            <button class="btn btn-outline-secondary btn-calendar-icon" (click)="d1.toggle()"
                              type="button">
                              <img src="./assets/images/icon/calendar-icon.svg" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
  
                      <div class="width-134 field-groups">
                        <div class="mt-3">
                          <label for="expectedDate" class="form-title text-nowrap"> Expected Date
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.expectedDate }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-group">
                            <input id="expectedDate" class="form-control" placeholder="YYYY-MM-DD" name="d2"
                              formControlName="expectedDate" ngbDatepicker #d2="ngbDatepicker"  />
                            <button class="btn btn-outline-secondary btn-calendar-icon" (click)="d2.toggle()"
                              type="button">
                              <img src="./assets/images/icon/calendar-icon.svg" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
  
                      <div class="width-90 field-groups">
                        <div class="mt-3">
                          <label for="pod" class="form-title text-nowrap">
                            PoD
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.pod }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="pod"
                            id="pod" />
                        </div>
                        
                      </div>
  
  
                      <div class="width-185 field-groups">
                        <div class="margin-top-13px">
                          <label for="attachPod" class="form-title text-nowrap">
                            Attach PoD
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.attachPod }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="form-group file-upload">
                            <input type="file" class="file-border" id="image_file" #fileUploader
                              (change)="fileChange($event)" accept=".pdf,.doc,.docx,.xls,.xlsx">
                          </div>
                        </div>
                      </div>
  
                      <div class="width-111 field-groups">
                        <div class="mt-3">
                          <label for="invoiceNo" class="form-title text-nowrap">
                            Invoice No
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.invoiceNo }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="invoiceNo"
                            id="invoiceNo" />
                        </div>
                      </div>

                      <div class="width-185 field-groups">
                        <div class="margin-top-13px">
                          <label for="attachInvoice" class="form-title text-nowrap">
                            Attach Invoice
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.attachInvoice }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="form-group file-upload">
                            <input type="file" class="file-border" id="image_file2" #fileUploader
                              (change)="fileChange($event)" accept=".pdf,.doc,.docx,.xls,.xlsx">
                          </div>
                        </div>
                      </div>
  
                      <div class="width-148 field-groups" *ngIf="isUnScheduled">
                        <div class="margin-top-13px d-flex align-items-center">
                          <input type="checkbox" [(ngModel)]="checkedUnScheduledETR" [ngModelOptions]="{standalone: true}"
                            class="me-1 custom-checkbox"  />
                          <label for="unscheduledEtr" class="mb-0 form-title text-nowrap">
                            Unscheduled c-ETR
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.unscheduledEtr }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                        </div>
                      </div>
  
                      <div class="width-135 field-groups" *ngIf="isVatIncluded">
                        <div class="mt-3  d-flex align-items-center">
                          <input type="checkbox" [(ngModel)]="checkedVatIncluded" [ngModelOptions]="{standalone: true}"
                            class="me-1 custom-checkbox"  />
                          <label for="vatIncluded" class="mb-0 form-title text-nowrap">
                            VAT included
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.vatIncluded }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                        </div>
                      </div>
  
                      <div class="col-md-12 field-groups c-ETR-Terms">
                        <div class="mt-3">
                          <label for="loanTerms" class="form-title text-nowrap">
                            c-ETR Loan Terms
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.loanTerms }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <app-loan-agreement></app-loan-agreement>
                        </div>
                      </div>
                    </div>
  
                    <div class="col-12 bottom-fixed">
                      <div class="d-flex justify-content-center mt-3">
                        <button [disabled]="addForm.invalid" type="submit"
                          class="btn btn-light me-3 btn-custom-footer save_btn">
                          Save
                        </button>
                        <button type="button" class="btn btn-light btn-custom-footer cancel_btn" data-dismiss="modal"
                          (click)="closeModal()">
                          Cancel
                        </button>
                      </div>
                    </div>
  
                  </form>
  
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Open A Trade -->
      <ng-container *ngIf="pageTitle==='Open A Trade'">
        <div  class="modal-body padding-model form-editing">
          <div class="row">
            <div class="col-lg-12">
              <div class="card w-100 mb-0">
                <div class="card  mb-0">
                  <form *ngIf="addForm" [formGroup]="addForm" (ngSubmit)="onSubmit()">
                    <div class="row">
  
                      <div class="width-115 field-groups">
                        <div class="mt-3">
                          <label for="debtor" class="form-title text-nowrap"> Revolving ETR
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.debtor }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>

                        <ng-select
                          class="custom"
                          id="debtor"
                          [style.flex]="1"
                          [items]="debtorList"
                          bindLabel="combinedLabel"
                          bindValue="id"
                          placeholder="Select Debtor"
                          formControlName="debtor"
                          (change)="onDebtorChange($event,'open_a_trade')"
                          [searchFn]="customSearch"
                        >
                          <ng-template ng-label-tmp let-item="item">
                            {{ item.name }}
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item">
                            <span [title]="item.name" tooltip="item.name">{{ item.id }} - {{ item.name }}</span>
                          </ng-template>
                        </ng-select>  

                        
                        </div>
                      </div>

                      <div class="width-98 field-groups" style="margin-right: 10px;" >
                        <div class="mt-3">
                          <label for="days" class="form-title text-nowrap">
                            Payment Terms
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.paymentTerms }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-container input-container d-flex gap-1 align-items-center input_days">
                            <input id="days"
                             type="text" 
                             [appOnlyNumber]="false"
                             class="form-control sd-width inputNumberText"
                              placeholder="" 
                              formControlName="days" 
                              maxlength="4"  />
                            <span>Days</span>
                          </div>
                        </div>
                      </div>

                      <div class="width-50 field-groups" style="margin-left: 1px;">
                        <div class="mt-3">
                          <label for="ccy" class="form-title text-nowrap">
                            Ccy
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.ccy }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <span id="ccy" class="d-block text-nowrap">{{addForm.get('ccy')?.value }}</span>
                        </div>
                      </div>

                      <div class="width-85 field-groups">
                        <div class="mt-3">
                          <label for="faceValue" class="form-title text-nowrap">
                            Face Value
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.totalFaceValueofETR }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" [appOnlyNumber]="true"
                            class="form-control text-left ft-width inputNumberText" maxlength="10" placeholder=""
                            formControlName="faceValue" id="faceValue"  />
 
                        </div>
                      </div>

                      <div class="width-55 field-groups">
                        <div class="mt-3">
                          <label for="numberofetr" class="form-title text-nowrap">
                            No of ETR
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.numberofetr }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" [appOnlyNumber]="false"
                            class="form-control text-left ft-width inputNumberText" maxlength="10" placeholder=""
                            formControlName="numberofetr" id="numberofetr" />
                        </div>
                      </div>

                      <div class="width-45 field-groups">
                        <div class="mt-3">
                          <label for="months" class="form-title text-nowrap">
                            Mos
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.months }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" [appOnlyNumber]="false" class="form-control sd-width inputNumberText" placeholder=""
                            formControlName="months" id="months"  maxlength="10"/>
                        </div>
                      </div>
                      <div class="width-180 field-groups">
                        <div class="margin-top-14px">
                          <label for="supportingDocument" class="form-title">
                            Attach  Document
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.supportingDocument }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="form-group file-upload">
                            <input type="file" class="file-border" id="image_file" #fileUploader
                              (change)="fileChange($event)" accept=".pdf,.doc,.docx,.xls,.xlsx">
                          </div>
                        </div>
                      </div>

                     <div class="field-groups width-80" >
                        <div class="mt-3">
                          <label for="monthlyDiscount" class="form-title text-nowrap">
                            Monthly
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.monthlyDiscount }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-container input-container d-flex gap-1 align-items-center">
                            <input id="monthlyDiscount" type="text" class="form-control sd-width inputNumberText"
                              placeholder="" formControlName="monthlyDiscount" maxlength="10" [readOnly]="true" />
                            <span>%</span>
                          </div>
                        </div>
                      </div>

                      <div class="field-groups width-80" >
                        <div class="mt-3">
                          <label for="buyOutDiscount" class="form-title text-nowrap">
                            Buy Out
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.buyOutDiscount }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-container input-container d-flex gap-1 align-items-center">
                            <input id="buyOutDiscount" type="text" class="form-control sd-width inputNumberText"
                              placeholder="" formControlName="buyOutDiscount" maxlength="10" [readOnly]="true" />
                            <span>%</span>
                          </div>
                        </div>
                      </div>

                      <div class="width-125 field-groups">
                        <div class="mt-3 d-flex align-items-center">
                          <input 
                            type="checkbox" 
                            class="me-1 custom-checkbox" 
                            [checked]="true" 
                            id="allowBuyOut"
                          />
                          <label for="allowBuyOut" class="mb-0 form-title text-nowrap">
                            Allow Buy Out
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.allowBuyOut }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div class="width-135 field-groups">
                        <div class="mt-3  d-flex align-items-center">
                          <input type="checkbox" [(ngModel)]="checkedVatIncluded" [ngModelOptions]="{standalone: true}"
                            class="me-1 custom-checkbox"  />
                          <label for="vatIncluded" class="mb-0 form-title text-nowrap">
                            Use <a class="text-blue cursor-pointer">ETR Collection Service </a> for this invoice
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.vatIncluded }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                        </div>
                      </div> 
                 
                      <div class="field-groups">
                        <div class="mt-3">
                          <label for="description" class="form-title text-nowrap">
                            	Description
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.description }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <textarea 
                            class="form-control ft-width" 
                            placeholder="Type description here..."
                            formControlName="description" id="description" row="8" maxlength="512" >
                           </textarea>
 
                        </div>
                      </div>
                    </div>
  
                    <div class="col-12 bottom-fixed">
                      <div class="d-flex justify-content-center mt-3">
                        <button [disabled]="addForm.invalid" type="submit"
                          class="btn btn-light me-3 btn-custom-footer save_btn">
                          Save
                        </button>
                        <button type="button" class="btn btn-light btn-custom-footer cancel_btn" data-dismiss="modal"
                          (click)="closeModal()">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Revolving ETR Invoice Upload -->
      <ng-container *ngIf="pageTitle==='Revolving ETR Invoice Upload'">
        <div  class="modal-body padding-model form-editing">
          <div class="row">
            <div class="col-lg-12">
              <div class="card w-100 mb-0">
                <div class="card  mb-0">
                  <form *ngIf="addForm" [formGroup]="addForm" (ngSubmit)="onSubmit()">
                    <div class="row">
  
                      <div class="width-130 field-groups">
                        <div class="mt-3">
                          <label for="debtor" class="form-title text-nowrap">  Revolving ETR
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.revolvingETR }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
  
                          <ng-select
                          class="custom"
                          id="debtor"
                          [style.flex]="1"
                          [items]="debtorList"
                          bindLabel="combinedLabel"
                          bindValue="id"
                          placeholder="Select Debtor"
                          formControlName="debtor"
                          (change)="onDebtorChange($event,'revolving')"
                          [searchFn]="customSearch"
                        >
                          <ng-template ng-label-tmp let-item="item">
                            {{ item.name }}
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item">
                            <span [title]="item.name" tooltip="item.name">{{ item.id }} - {{ item.name }}</span>
                          </ng-template>
                        </ng-select>  
                        </div>
                      </div>

                      <div class="width-110 field-groups" *ngIf="this.isbEtr" >
                        <div class="mt-3">
                          <label for="originator" class="form-title text-nowrap">
                            Select b-ETR
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.originator }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-group">
                            <ng-select
                            class="custom-form-select border-radius-100"
                            id="originator"
                            [style.flex]="1"
                            [items]="originatorList2"
                            bindLabel="label"
                            bindValue="id"
                            placeholder="Select b-ETR"
                            formControlName="originator"
                            (change)="onOriginatorChange($event, 'revolving')"
                            [searchFn]="customSearch"
                          >
                            <ng-template ng-label-tmp let-item="item">
                              {{ item.label }}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                              <span [title]="item.label"> {{ item.label }}</span>
                            </ng-template>
                          </ng-select>

                          </div>
                        </div>
                      </div>
                      
                      <div class="width-110 field-groups">
                        <div class="mt-3">
                          <label for="email" class="form-title text-nowrap"> Deliver NoA to
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.deliverNoA }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-group">
                            <select class="custom-form-select border-radius-100" id="email" formControlName="email"
                             >
                             <option value="" selected="true" [disabled]="true">All</option>
                              <option *ngFor="let item of emails" [value]="item.email">
                                {{ item.email }} 
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                     
                      <div class="width-98 field-groups" style="margin-right: 10px;">
                        <div class="mt-3">
                          <label for="days" class="form-title text-nowrap">
                            Payment Terms
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.paymentTerms }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-container input-container d-flex gap-1 align-items-center input_days">
                            <input id="days"
                             type="text" 
                             [appOnlyNumber]="false"
                             class="form-control sd-width inputNumberText"
                              placeholder="" 
                              formControlName="days" 
                              maxlength="4"  />
                            <span>Days</span>
                          </div>
                        </div>
                      </div>

                      <div class="width-50 field-groups" style="margin-left: 1px;">
                        <div class="mt-3">
                          <label for="ccy" class="form-title text-nowrap">
                            Ccy
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.ccy }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <span id="ccy" class="d-block text-nowrap">{{addForm.get('ccy')?.value }}</span>
                        </div>
                      </div>
  
                      <div class="width-85 field-groups">
                        <div class="mt-3">
                          <label for="faceValue" class="form-title text-nowrap">
                            Face Value
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.faceValue }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" [appOnlyNumber]="true"
                            class="form-control text-left ft-width inputNumberText" maxlength="10" placeholder=""
                            formControlName="faceValue" id="faceValue" (input)="onFaceValueChange('revolving')"/>
  
                        </div>
                      </div>
  
                      <div class="width-120 field-groups">
                        <div class="mt-3">
                          <label for="remainingValue" class="form-title text-nowrap">
                            Remaining Value
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="{{ tooltipData.remainingValue }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <span id="remainingValue" class="d-block text-nowrap text-end">{{
                            addForm.get('remainingValue')?.value
                            }}</span>
                        </div>
                      </div>
  
                      <div class=" width-134 field-groups">
                        <div class="mt-3">
                          <label for="invoiceDate" class="form-title text-nowrap"> Invoice Date
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.invoiceDate }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-group">
                            <input id="invoiceDate" class="form-control" placeholder="YYYY-MM-DD" name="d3"
                              formControlName="invoiceDate" ngbDatepicker #d3="ngbDatepicker"  />
                            <button class="btn btn-outline-secondary btn-calendar-icon" (click)="d3.toggle()"
                              type="button">
                              <img src="./assets/images/icon/calendar-icon.svg" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
  
                      <div class="width-134 field-groups">
                        <div class="mt-3">
                          <label for="expectedDate" class="form-title text-nowrap"> Expected Date
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.expectedDate }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-group">
                            <input id="expectedDate" class="form-control" placeholder="YYYY-MM-DD" name="d4"
                              formControlName="expectedDate" ngbDatepicker #d4="ngbDatepicker"  />
                            <button class="btn btn-outline-secondary btn-calendar-icon" (click)="d4.toggle()"
                              type="button">
                              <img src="./assets/images/icon/calendar-icon.svg" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
  
                      <div class="width-95 field-groups">
                        <div class="mt-3">
                          <label for="pod" class="form-title text-nowrap">
                            PoD
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.pod }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input *ngIf="!isPod_DD" type="text" class="form-control sd-width" placeholder="" formControlName="pod"
                            id="pod" />
                            <div class="input-group" *ngIf="isPod_DD">
                              <select class="custom-form-select border-radius-100" id="pod"
                                formControlName="pod">
                                <option value="" selected="true" [disabled]="true">Select PO</option>
                                <option value="125061" >125061 | Avaliable GBP -10.96</option>
                              </select>
                            </div>
                        </div>
                      </div>
  
                      <div class="width-180 field-groups">
                        <div class="margin-top-13px">
                          <label for="attachPod" class="form-title text-nowrap">
                            Attach PoD
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.attachPod }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="form-group file-upload">
                            <input type="file" class="file-border" id="image_file" #fileUploader
                              (change)="fileChange($event)" accept=".pdf,.doc,.docx,.xls,.xlsx">
                          </div>
                        </div>
                      </div>
  
                      <div class="width-100 field-groups">
                        <div class="mt-3">
                          <label for="invoiceNo" class="form-title text-nowrap">
                            Invoice No
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.invoiceNo }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="invoiceNo"
                            id="invoiceNo" />
                        </div>
                      </div>
  
                     
  
                      <div class="width-180 field-groups">
                        <div class="margin-top-13px">
                          <label for="attachInvoice" class="form-title text-nowrap">
                            Attach Invoice
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.attachInvoice }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="form-group file-upload">
                            <input type="file" class="file-border" id="image_file2" #fileUploader
                              (change)="fileChange($event)" accept=".pdf,.doc,.docx,.xls,.xlsx">
                          </div>
                        </div>
                      </div>

                      <div class="width-135 field-groups">
                        <div class="mt-3  d-flex align-items-center">
                          <input type="checkbox" [(ngModel)]="checkedVatIncluded" [ngModelOptions]="{standalone: true}"
                            class="me-1 custom-checkbox" />
                          <label for="vatIncluded" class="mb-0 form-title text-nowrap">
                            Use <a class="text-blue cursor-pointer">ETR Collection Service </a> for this invoice
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.vatIncluded }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                        </div>
                      </div>

                    </div>
  
                    <div class="col-12 bottom-fixed">
                      <div class="d-flex justify-content-center mt-3">
                        <button [disabled]="addForm.invalid" type="submit"
                          class="btn btn-light me-3 btn-custom-footer save_btn">
                          Save
                        </button>
                        <button [disabled]="addForm.invalid" type="submit"
                          class="btn btn-light me-3 btn-custom-footer submit_btn">
                          Submit & Automatic Send
                        </button>
                        <button [disabled]="addForm.invalid" type="submit"
                          class="btn btn-light me-3 btn-custom-footer submit_btn">
                          Submit & Manual Send
                        </button>
                        <button type="button" class="btn btn-light btn-custom-footer cancel_btn" data-dismiss="modal"
                          (click)="closeModal()">
                          Cancel
                        </button>
                      </div>
                    </div>
  
                  </form>
  
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

       <!-- Open b-ETR Trade -->
      <ng-container *ngIf="pageTitle==='Open b-ETR Trade'">
        <div  class="modal-body padding-model form-editing open_b_ETR_Trade ">
          <div class="row">
            <div class="col-lg-12">
              <div class="card w-100 mb-0">
                <div class="card  mb-0">
                  <form *ngIf="addForm" [formGroup]="addForm" (ngSubmit)="onSubmit()">
                    <div class="row">
  
                      <div class="width-134 field-groups">
                        <div class="mt-3">
                          <label for="expectedDate" class="form-title text-nowrap"> Expected Date
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.expectedDate }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-group">
                            <input id="expectedDate" class="form-control" placeholder="YYYY-MM-DD" name="d5"
                              formControlName="expectedDate" ngbDatepicker #d5="ngbDatepicker"  />
                            <button class="btn btn-outline-secondary btn-calendar-icon" (click)="d5.toggle()"
                              type="button">
                              <img src="./assets/images/icon/calendar-icon.svg" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="width-110 field-groups">
                        <div class="mt-3">
                          <label for="friendlyName" class="form-title text-nowrap">
                            Friendly Name
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.friendlyName }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="friendlyName"
                            id="friendlyName" />
                        </div>
                      </div>

                      <div class="field-groups width-75" >
                        <div class="mt-3">
                          <label for="monthlyDiscount" class="form-title text-nowrap">
                            d-ETR 
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="d-ETR Monthly Discount">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-container input-container d-flex gap-1 align-items-center">
                            <input id="monthlyDiscount" type="text" class="form-control sd-width inputNumberText"
                              placeholder="" formControlName="monthlyDiscount" maxlength="10" [readOnly]="true" />
                            <span>%</span>
                          </div>
  
                        </div>
                      </div>
                      <div class="field-groups width-75" >
                        <div class="mt-3">
                          <label for="sellRate" class="form-title text-nowrap">
                            c-ETR 
                            <span class="tooltip-ng ms-0" placement="bottom"
                              ngbTooltip="c-ETR Sell Rate Discount">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-container input-container d-flex gap-1 align-items-center">
                            <input id="sellRate" type="text" class="form-control sd-width inputNumberText"
                              placeholder="" formControlName="sellRate" maxlength="10" [readOnly]="true" />
                            <span>%</span>
                          </div>
  
                        </div>
                      </div>

                      <div class="width-180 field-groups">
                        <div class="margin-top-13px">
                          <label for="supportingDocument" class="form-title text-nowrap">
                            Attach  Document
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.supportingDocument }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="form-group file-upload">
                            <input type="file" class="file-border" id="image_file" #fileUploader
                              (change)="fileChange($event)" accept=".pdf,.doc,.docx,.xls,.xlsx">
                          </div>
                        </div>
                      </div>


                      <div class="field-groups">
                        <div class="mt-3">
                          <label for="description" class="form-title text-nowrap">
                            	Description
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.description }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <textarea 
                            class="form-control ft-width" 
                            placeholder="Type description here..."
                            formControlName="description" id="description" row="8" maxlength="512" >
                           </textarea>
 
                        </div>
                      </div>
                    </div>
  
                    <div class="col-12 bottom-fixed">
                      <div class="d-flex justify-content-center mt-3">
                        <button [disabled]="addForm.invalid" type="submit"
                          class="btn btn-light me-3 btn-custom-footer save_btn">
                          Save
                        </button>
                        <button type="button" class="btn btn-light btn-custom-footer cancel_btn" data-dismiss="modal"
                          (click)="closeModal()">
                          Cancel
                        </button>
                      </div>
                    </div>
  
                  </form>
  
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

     <!-- Upload PO -->
      <ng-container *ngIf="pageTitle==='Upload PO'">
        <div  class="modal-body padding-model form-editing">
          <div class="row">
            <div class="col-lg-12">
              <div class="card w-100 mb-0">
                <div class="card  mb-0">
                  <form *ngIf="addForm" [formGroup]="addForm" (ngSubmit)="onSubmit()">
                    <div class="row">
  
                      <div class="width-115 field-groups">
                        <div class="mt-3">
                          <label for="bEtr" class="form-title text-nowrap">  b-ETR
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.bEtr }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-group">

                            <ng-select
                              class="custom"
                              id="bEtr"
                              [items]="bETRList"
                              bindLabel="name"
                              bindValue="id"
                              placeholder="Select b-ETR"
                              formControlName="bEtr"
                              [searchFn]="customSearch"
                            >
                              <ng-template ng-label-tmp let-item="item">
                                {{ item.name }} ({{ item.originatorId }})
                              </ng-template>
                              <ng-template ng-option-tmp let-item="item">
                                <span [title]="item.name">{{ item.name }} ({{ item.originatorId }})</span>
                              </ng-template>
                            </ng-select>


                          </div>
                        </div>
                      </div>

                      <div class="width-120 field-groups">
                        <div class="mt-3">
                          <label for="debtor" class="form-title text-nowrap"> Upload PO for 
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="	Upload PO for">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>

                        <ng-select
                          class="custom"
                          id="debtor"
                          [style.flex]="1"
                          [items]="debtorList"
                          bindLabel="combinedLabel"
                          bindValue="id"
                          placeholder="Select Debtor"
                          formControlName="debtor"
                          (change)="onDebtorChange($event,'upload_po')"
                          [searchFn]="customSearch"
                        >
                          <ng-template ng-label-tmp let-item="item">
                            {{ item.name }}
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item">
                            <span [title]="item.name" tooltip="item.name">{{ item.id }} - {{ item.name }}</span>
                          </ng-template>
                        </ng-select>  

                        </div>
                      </div>

                      <div class="width-50 field-groups" style="margin-left: 1px;">
                        <div class="mt-3">
                          <label for="ccy" class="form-title text-nowrap">
                            Ccy
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.ccy }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <span id="ccy" class="d-block text-nowrap">{{addForm.get('ccy')?.value }}</span>
                        </div>
                      </div>

                      <div class="width-134 field-groups">
                        <div class="mt-3">
                          <label for="poDate" class="form-title text-nowrap"> PO Date
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.poDate }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-group">
                            <input id="poDate" class="form-control" placeholder="YYYY-MM-DD" name="d6"
                              formControlName="poDate" ngbDatepicker #d6="ngbDatepicker"  />
                            <button class="btn btn-outline-secondary btn-calendar-icon" (click)="d6.toggle()"
                              type="button">
                              <img src="./assets/images/icon/calendar-icon.svg" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="width-85 field-groups">
                        <div class="mt-3">
                          <label for="faceValue" class="form-title text-nowrap">
                            Face Value
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.faceValue }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" [appOnlyNumber]="true"
                            class="form-control text-left ft-width inputNumberText" maxlength="10" placeholder=""
                            formControlName="faceValue" id="faceValue" (input)="onFaceValueChange('open_a_trade')" />
                        </div>
                      </div>
            
                      <div class="width-100 field-groups">
                        <div class="mt-3">
                          <label for="poNumber" class="form-title text-nowrap">
                            PO No
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.poNumber }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <input type="text" class="form-control sd-width" placeholder="" formControlName="poNumber"
                            id="poNumber" />
                        </div>
                      </div>
  
                      <div class="width-180 field-groups">
                        <div class="margin-top-13px">
                          <label for="pofile" class="form-title text-nowrap">
                            PO file
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.pofile }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="form-group file-upload">
                            <input type="file" class="file-border" id="image_file" #fileUploader
                              (change)="fileChange($event)" accept=".pdf,.doc,.docx,.xls,.xlsx">
                          </div>
                        </div>
                      </div>

                    </div>
  
                    <div class="col-12 bottom-fixed">
                      <div class="d-flex justify-content-center mt-3">
                        <button [disabled]="addForm.invalid" type="submit"
                          class="btn btn-light me-3 btn-custom-footer save_btn">
                          Save
                        </button>
                        <button type="button" class="btn btn-light btn-custom-footer cancel_btn" data-dismiss="modal"
                          (click)="closeModal()">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
        

    </div>
  </div>
  </div>