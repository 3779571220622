<form [formGroup]="form">
  <div class="card-body header-title">
    <div class="row">
      <div class="col-md-2">
        <p class="card-title ps-1">{{ label }}</p>
      </div>
      <div
        class="col-md-10 text-sm-end d-flex justify-content-end custom-invest-filter"
      >
        <div
          *ngIf="ccyList?.length"
          class="me-2 d-flex align-items-center daterange_sec select_bank_filter"
        >
          <label class="me-1">Ccy:</label>
          <select
            formControlName="ccy"
            class="custom-filter-select border-radius-100 select_currency"
            placeholder="Select bank code"
          >
            <option value="" selected="selected">All</option>
            <option *ngFor="let list of ccyList" [value]="list.id">
              {{ list.name }}
            </option>
          </select>
        </div>
        <div
          *ngIf="tradeTransactionList?.length"
          class="me-2 d-flex align-items-center daterange_sec select_clutch_filter"
        >
          <label class="me-1">ETR:</label>
          <select
            class="custom-filter-select border-radius-100"
            formControlName="type"
          > <option value="" selected="selected">All</option>
            <option *ngFor="let type of tradeTransactionList" [value]="type?.id">
              {{ type?.name }}
            </option>
          </select>
        </div>
        <div
          *ngIf="clutchList?.length"
          class="me-2 d-flex align-items-center daterange_sec select_clutch_filter"
        >
          <label class="me-1">Clutch:</label>
          <select
            class="custom-filter-select border-radius-100"
            formControlName="clutch"
          >
            <option *ngFor="let clutch of clutchList" [value]="clutch?.id">
              {{ clutch?.name }}
            </option>
          </select>
        </div>
        <div
          *ngIf="banksList?.length"
          class="me-2 d-flex align-items-center daterange_sec select_bank_filter bank_mutiselect mutiselect_account"
        >
          <label class="me-1">Bank:</label>
          <ng-select
            [items]="banksList"
            bindLabel="name"
            bindValue="name"
            [multiple]="filtersMultiple"
            [closeOnSelect]="false"
            groupBy=""
            [selectableGroup]="false"
            placeholder="All"
            formControlName="bank"
          >
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div
                class="ng-value"
                *ngFor="let item of items ? items.slice(0, 4) : []"
              >
                <span class="ng-value-label">{{ item.name }}</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div
          *ngIf="accountsList?.length"
          class="me-2 d-flex align-items-center daterange_sec select_account_filter mutiselect_account"
        >
          <label class="me-1">Account:</label>
          <ng-select
            [items]="accountsList"
            bindLabel="account_no"
            bindValue="account_no"
            [multiple]="filtersMultiple"
            [closeOnSelect]="false"
            groupBy="bank_code"
            [selectableGroup]="false"
            placeholder="All"
            formControlName="account"
          >
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div
                class="ng-value"
                *ngFor="let item of items ? items.slice(0, 4) : []"
              >
                <span class="ng-value-label">{{ item.account_no }}</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div class="me-2 d-flex align-items-center daterange_sec search_filter" *ngIf="isSearch">
          <input
            type="text"
            placeholder="Search"
            class="me-0 btn-light text-start custom-tab-button"
            formControlName="search"
          />
        </div>

        <div class="me-2 d-flex align-items-center daterange_sec">
          <button
            *ngIf="showNewButton"
            type="button"
            class="btn btn-light custom-tab-button"
            (click)="openAddNewModal('add')"
            [disabled]="disabled"
          >
            New
          </button>
          <div  *ngIf="rangeSelector" class="m-0 date-range d-flex align-items-center single_select_filter">
            <button
            type="button"
            class="btn btn-light custom-tab-button"
            (click)="onShowData('All')"
            [ngClass]="{ active: currentFilter == 'All' }"
            [disabled]="disabled"
          >
            All
          </button>
          <button
            type="button"
            class="btn btn-light custom-tab-button"
            (click)="onShowData('Month')"
            [ngClass]="{ active: currentFilter == 'Month' }"
          >
            Month
          </button>
          <button
            type="button"
            class="btn btn-light custom-tab-button"
            (click)="onShowData('Year')"
            [ngClass]="{ active: currentFilter == 'Year' }"
            [disabled]="disabled"
          >
            Year
          </button>
          <button
            type="button"
            class="btn btn-light custom-tab-button"
            (click)="onShowData('Date')"
            [ngClass]="{ active: currentFilter == 'Date' }"
            [disabled]="disabled"
          >
            Range
          </button>
          </div>  
          <div
            *ngIf="currentFilter === 'Month'"
            class="m-0 date-range d-flex align-items-center single_select_filter"
          >
            <label class="me-1">Month:</label>
            <select
              class="custom-filter-select border-radius-100"
              id="MonthList"
              formControlName="month"
            >
              <option value="" selected="selected">All</option>
              <option *ngFor="let list of monthList" [value]="list?.date">
                {{ list?.date }}
              </option>
            </select>
          </div>
          <div
            class="date-range d-flex align-items-center m-0 single_select_filter"
            *ngIf="currentFilter === 'Year'"
          >
            <label class="me-1">Year:</label>
            <select
              class="custom-filter-select border-radius-100"
              id="financialList"
              formControlName="year"
            >
              <option *ngFor="let list of financialList" [value]="list?.id">
                {{ list?.name }}
              </option>
            </select>
          </div>
          <div
            class="d-flex align-items-center single_select_filter"
            *ngIf="currentFilter !== 'Year' && currentFilter !== 'Month' && rangeSelector"
          >
            <label class="me-1">Date:</label>
            <input
              type="search"
              class="form-control from-daterange mt-0"
              placeholder="Start Date - End Date"
              bsDaterangepicker
              [bsConfig]="{
                rangeInputFormat: 'YYYY-MM-DD',
                dateInputFormat: 'YYYY-MM-DD',
                showWeekNumbers: false
              }"
              formControlName="startDate"
              autocomplete="off"
            />
          </div>
        </div>
        <select
          class="custom-invest-select border-radius-100 ng-pristine ng-valid ng-touched filter-pagesize selectbox-per-page"
          id="pageSize"
          formControlName="perPage"
        >
          <option
            *ngFor="let size of pageSizes"
            [value]="size"
            class="ng-star-inserted text-end"
          >
            {{ size | number: "1.0" : "en-US" }} per page
          </option>
        </select>
        <div class="action-buttons-group">
          <button
            type="button"
            class="btn custom-invest-button print_btn"
            (click)="print.emit()"
          >
            <img
              src="./assets/images/icon/printer.svg"
              width="16px"
              alt="printer"
            />
          </button>
          <button
            type="button"
            class="btn custom-invest-button"
            [disabled]="disabled"
          >
            <img
              (click)="export.emit()"
              src="./assets/images/icon/download_icon.svg"
              width="16px"
              alt="download"
            />
          </button>

            <button
              type="button"
              (click)="resetFormGroup()"
              class="btn custom-invest-button"
              [disabled]="disabled"
            >
              <img src="./assets/images/icon/replay.svg" width="16px" />
            </button>
          <button type="button" class="btn custom-invest-button me-0" disabled>
            <img
              src="./assets/images/icon/document.svg"
              width="16px"
              alt="document"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
