export const  TooltipData_Debtor_Creditor = {
    type: 'Type',
    country: 'Country',
    currency : 'Currency',
    organisation : 'Organisation',
    companyName: 'Company Name',
    tradingName: 'Trading Name',
    companyRegistrationNumber : 'Company Registration Number',
    previousName: 'Previous Name',
    address : 'Address',
    city : 'City',
    state : 'State',
    postCode : 'Post Code',
    phoneNumber : 'Phone Number',
    otherNumber : 'Other Number',
    email : 'Email',
    contactFirstName: 'Contact First Name',
    contactLastName: 'Contact Last Name',
    contactPhoneNumber: 'Contact Phone Number',
    contactEmail: 'Contact Email',
    counterPartyYear: 'Counter PartyYear',
    historicValue: 'Historic Value',
    paymentCycle: 'Payment Cycle',
    notesIssued: 'Notes Issued',
    invoices: 'Invoices',
    annualRevenue: 'Annual Revenue',
    bankName: 'Bank Name',
    iban: 'Iban',
    swiftCode: 'Swift Code',
    accountNumber: 'Account Number',
  };