<nav class="sidebar-nav custom-scrollbar">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length !== 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.title}}</div>
            <a class="sidebar-link waves-effect waves-dark" [routerLink]="!!sidebarnavItem.path ? [sidebarnavItem.path] : null"
                [class.has-arrow]="sidebarnavItem.class === 'collapse'"
                [ngClass]="[sidebarnavItem.class]" *ngIf="!shouldDisplayItem(sidebarnavItem)" (click)="addExpandClass(sidebarnavItem.title,sidebarnavItem.class)"
                [routerLinkActive]="sidebarnavItem.submenu.length !== 0 ? '' : 'active'">
                <img src="{{sidebarnavItem.image}}" class="menu-image" alt="">
                <span class="hide-menu">{{sidebarnavItem.title}}</span>
            </a>
            <a 
            (click)="callModal(sidebarnavItem.title,sidebarnavItem.class)"
             class="sidebar-link waves-effect waves-dark"
            [class.has-arrow]="sidebarnavItem.class === 'collapse'"
            [ngClass]="[sidebarnavItem.class]" *ngIf="shouldDisplayItem(sidebarnavItem)" style="cursor: pointer;">
            <img src="{{sidebarnavItem.image}}" class="menu-image" alt="">
            <span class="hide-menu">{{sidebarnavItem.title}}</span>
        </a>
            <ul aria-expanded="false" class="collapse first-level" [class.in]="showMenu === sidebarnavItem.title">
                <li class="sidebar-item" *ngFor="let submenu of sidebarnavItem.submenu">
                  <a href="javascript:void(0)" class="sidebar-link" 
                  [class.active]="showSubMenu === submenu.title"
                  [routerLink]="!!submenu.path ? [submenu.path] : null"
                  (click)="addExpandSubClass(submenu.title,submenu.class)"
                  >
                    <img src="{{submenu.image}}" class="menu-image" alt="">
                    <span class="hide-menu"> {{submenu.title}} </span>
                  </a>
                </li>
              </ul>
        </li>
    </ul>
</nav>
