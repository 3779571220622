import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class Add_Debtor_Creditor_FormProvider {
  form: FormGroup;

  constructor(private fb: FormBuilder) { }

  initForm(data?: any): FormGroup {
    return (this.form = this.fb.group({
      id: [data?.id || ''],
      type: [data?.type || '', [Validators.required]],
      country: [data?.country || '', [Validators.required]],
      currency: [data?.Currency || '', [Validators.required]],
      organisation: [data?.organisation || '', [Validators.required]],
      companyName: [data?.companyName || '', [Validators.required]],
      tradingName: [data?.tradingName || ''],
      companyRegistrationNumber: [data?.companyRegistrationNumber || '', [Validators.required]],
      previousName: [data?.previousName || ''],
      address1: [data?.address1 || '', [Validators.required]],
      address2: [data?.address2 || ''],
      city: [data?.city || ''],
      state: [data?.state || '', [Validators.required]],
      postCode: [data?.postCode || '', [Validators.required]],
      phoneNumber: [data?.phoneNumber || '', [Validators.required]],
      otherNumber: [data?.otherNumber || ''],
      email: [data?.email || '', [Validators.required, Validators.email]],
      billingAddress1: [data?.billingAddress1 || ''],
      billingAddress2: [data?.billingAddress2 || ''],
      billingCity: [data?.billingCity || ''],
      billingState: [data?.billingState || ''],
      billingPostCode: [data?.billingPostCode || ''],
      billingPhoneNumber: [data?.billingPhoneNumber || ''],
      billingOtherNumber: [data?.billingOtherNumber || ''],
      billingEmail: [data?.billingEmail || ''],
      contactFirstName: [data?.contactFirstName || '', [Validators.required]],
      contactLastName: [data?.contactLastName || ''],
      contactPhoneNumber: [data?.contactPhoneNumber || '', [Validators.required]],
      contactEmail: [data?.contactEmail || '', [Validators.required]],
      counterPartyYear: [data?.counterPartyYear || '', [Validators.required]],
      historicValue: [data?.historicValue || '', [Validators.required]],
      paymentCycle: [data?.paymentCycle || '', [Validators.required]],
      notesIssued: [data?.notesIssued || ''],
      invoices: [data?.invoices || '', [Validators.required]],
      annualRevenue: [data?.annualRevenue || '', [Validators.required]],
      bankId: [data?.bankId || ''],
      bankName: [data?.bankName || ''],
      iban: [data?.iban || ''],
      swiftCode: [data?.swiftCode || ''],
      accountNumber: [data?.accountNumber || ''],
    }));
  }
}
