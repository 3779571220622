import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { FxrateQueryParamsInterface } from '../shared/interfaces/fxrate-interface';
import { API_URL } from '../app.module';

@Injectable({
  providedIn: 'root',
})
export class HttpService {

  readonly intermediaryBaseUrl = environment.intermediaryUrl;
  constructor(
    @Inject(API_URL) public baseUrl: string,
    public http: HttpClient,
  ) {}

  postData(serviceName: string, data: any) {
    return this.http.post(this.buildUrl(serviceName), data, { headers: this.getHeaders() });
  }

  getData(serviceName: string) {
    return this.http.get(this.buildUrl(serviceName), { headers: this.getHeaders() });
  }

  deleteData(serviceName: string, id: any) {
    // Include headers in the request
    const options = { headers: this.getHeaders() };
    return this.http.delete(`${this.buildUrl(serviceName)}/${id}`, options);
  }

  getFxRateData(params: Partial<FxrateQueryParamsInterface>): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}fx-rates?`, {
      params: this.queryCleaner(params),
    });
  }

  getBankList() {
    const baseUrl = environment.intermediaryUrl + 'banks';
    return this.http.get(baseUrl, { headers: this.getHeaders() });
  }

  upload(file: any, type: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const uploadType = 'upload';
    const baseUrl = environment.url + 'csv/' + uploadType
    return this.http.post(baseUrl, formData);
  }


  queryCleaner<T>(params: Partial<T>): { [key: string]: any } {
    for (const paramsKey in params) {
      if (!params[paramsKey as keyof T] && params[paramsKey as keyof T] !== 0) {
        delete (params as any)[paramsKey];
      }
    }
    return params;
  }

  queryCleanerBulk<T>(params: Partial<T>): { [key: string]: any } {
    for (const paramsKey in params) {
      if (!params[paramsKey as keyof T] || params[paramsKey as keyof T] === '~') {
        (params as any)[paramsKey] = null;
      }
    }
    return params;
  }

  getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      //'Access-Control-Allow-Credentials': 'true',
    });
  }

  buildUrl(serviceName: string): string {
    return `${this.intermediaryBaseUrl}${serviceName}`;
  }
}
