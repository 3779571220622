
  <div class="login-container">
    <img src="../../assets/images/logos/logo-text-white.png" alt="img" class="login-logo">
    <div class="login-box">
      <div class="login-header">
        <h2>ORIGINATOR LOGIN</h2>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="input-box">
            <img src="../../assets/images/icon/user-name.svg" alt="icon"/>
            <input type="username" id="username" placeholder="Username" formControlName="username" required>
          </div>
          <div *ngIf="loginForm.controls['username'].invalid && (loginForm.controls['username'].dirty || loginForm.controls['username'].touched)">
            <div class="error-msg" *ngIf="loginForm.controls['username'].errors?.['required']">Username is required.</div>
          </div>
        </div>
        <div class="form-group">
          <div class="input-box">
          <img src="../../assets/images/icon/password-lock.svg" alt="icon"/>
          <input type="password" placeholder="Password" id="password" formControlName="password" required>
          </div>
          <div *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">
            <div class="error-msg" *ngIf="loginForm.controls['password'].errors?.['required']">Password is required.</div>
          </div>
        </div>
        <div class="form-group checkbox-sec d-flex align-items-center">
          <input type="checkbox" id="remember" [checked]="rememberMe" (change)="onRememberMeChange($event)" />
          <label for="remember">Remember Me</label>
        </div>
        <div class="form-group">
          <button type="submit" [disabled]="loginForm.invalid">{{loginText}}</button>
        </div>
      </form>
    </div>
  </div>