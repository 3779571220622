import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.gaurd';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'all-debtors-creditors',
        loadChildren: () => import('./debtor-creditor/debtor-creditor.module').then((m) => m.DebtorCreditorModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'currency-configurations',
        loadChildren: () => import('./currency-configurations/currency-configurations.module').then((m) => m.CurrencyConfigurationsModule),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () => import('./transactions/transactions.module').then((m) => m.TransactionsModule),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        loadChildren: () => import('./trade/trade.module').then((m) => m.TradeModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(Approutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

