export const comparator = (filterLocalDateAtMidnight: Date, cellValue: string) => {
  if (cellValue == null) {
    return 0;
  }
  const dateParts = cellValue.split('-');
  const year = Number(dateParts[0]);
  const month = Number(dateParts[1]);
  const day = Number(dateParts[2]);
  const cellDate = new Date(year, month - 1, day);
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
};

export const dateFilterParams = {
  comparator: comparator,

  inRangeFloatingFilterDateFormat: 'YYYY-MM-DD',
  maxNumConditions: 1,
  debounceMs: 1000,
  closeOnApply: true,
  filterOptions: ['equals', 'inRange', 'before', 'after'],
};
