import { CommonModule } from '@angular/common';
import { Component, OnInit, HostListener, SimpleChanges } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationComponent } from 'src/app/shared/header/navigation.component';
import { SidebarComponent } from 'src/app/shared/sidebar/sidebar.component';
import { NgbDropdownConfig, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-full-layout',
  standalone: true,
  imports: [RouterModule, SidebarComponent, NavigationComponent, CommonModule, NgbCollapseModule, NgbDropdownModule],
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
  providers: [NgbDropdownConfig],
})
export class FullComponent implements OnInit {
  public isCollapsed = false;
  public innerWidth = 0;
  public defaultSidebar = '';
  public showMobileMenu = false;
  public expandLogo = false;
  public sidebartype = 'full';
  public userName = 'Michelle Hennebry';

  constructor(
    public router: Router,
    config: NgbDropdownConfig,
  ) {
    config.placement = 'top-start';
    config.autoClose = false;
  }

  ngOnInit() {
    const islogined = localStorage.getItem('token');
    if (this.router.url === '/') {
      if (islogined) {
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate(['/login']);
      }
    }
    this.defaultSidebar = this.sidebartype;
    this.handleSidebar();
    this.getUserDetails();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.handleSidebar();
  }

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.sidebartype = 'full';
    } else {
      this.sidebartype = this.defaultSidebar;
    }
  }

  onLogoutClick() {
    localStorage.removeItem('token');
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  toggleSidebarType() {
    switch (this.sidebartype) {
      case 'full':
        this.sidebartype = 'mini-sidebar';
        break;
      case 'mini-sidebar':
        this.sidebartype = 'full';
        break;
      default:
    }
  }

  getUserDetails() {
    const storedData = localStorage.getItem('userData');
    const userDetails = storedData ? JSON.parse(storedData) : {};
    // this.userName = `${userDetails.firstName} ${userDetails.lastName}`;
  }
}
