export const PAGE_SIZES = [50, 100, 200, 500, 1000, 2500, 5000, 10000];
export const CURRENCIES = [
  {
    id: 'EUR',
    name: 'EUR',
    code: 'EUR',
  },
  {
    id: 'GBP',
    name: 'GBP',
    code: 'GBP',
  },
  {
    id: 'USD',
    name: 'USD',
    code: 'USD',
  },
];

export const ETR_TYPE = [
  { name: 'a-ETR' , id: 'a' },
  { name: 'b-ETR' , id: 'b'},
  { name: 'c-ETR', id: 'c' },
  { name: 'd-ETR' , id: 'd'},
  { name: 'f-ETR' , id: 'f'}
];

export const TYPES: Array<{ id: string; name: string }> = [
  {
    id: 'Debtor',
    name: 'Debtor',
  },
  {
    id: 'Creditor',
    name: 'Creditor',
  },
  
];

export const Transaction_Types = [
  {
    label: 'Debtors',
    options: [
      'Bank Transmission Receipt',
      'Notice of Debtor Remittance',
      'Credit Note issued to Debtor',
      'Reconcile a Debtor Account'
    ]
  },
  {
    label: 'Creditors',
    options: [
      'Reconcile a Creditor Account'
    ]
  }
];