export const numberValueFormatter = (params: any) => {
  let originalValue = params.value;
  let formattedValue = '';
  if (originalValue != 0 && originalValue != null) {
    formattedValue = originalValue?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    formattedValue = '-';
  }
  return formattedValue;
};

export const numberValueFormatter2 = (params: any) => {
  let originalValue = params.value;
  let formattedValue = '-';
  const decimal = decimalPointSettings.hasOwnProperty(params.colDef.colId) ? decimalPointSettings[params.colDef.colId] : 2;
  if (originalValue != 0 && originalValue != null) {
    formattedValue = originalValue?.toLocaleString('en-US', {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  }
  return formattedValue;
};

const decimalPointSettings: Record<string, number> = {
  gbp: 4,
  usd: 4,
  buyRate: 3,
  months: 0,
};
