import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class UserDetailsFormProvider {
  userDetailsForm: FormGroup;
  addNewUserForm: FormGroup;
  constructor(private fb: FormBuilder) {}

  initForm(data?: any): FormGroup {
    return (this.userDetailsForm = this.fb.group({
      firstName: [data?.firstName, [Validators.required]],
      lastName: [data?.lastName, [Validators.required]],
      email: [data?.email, [Validators.required, Validators.email]],
      username: [data?.username],
      organisationName: [data?.organisationName],
      addressLineOne: [data?.addressLineOne],
      addressLineTwo: [data?.addressLineTwo],
      dateOfBirth: [data?.dateOfBirth ? this.parseDate(data?.dateOfBirth) : null],
      city: [data?.city, [Validators.required]],
      state: [data?.state],
      area: [data?.area],
      countryCode: [data?.countryCode],
      zipCode: [data?.zipCode, [Validators.required, Validators.maxLength(10)]],
      country: [data?.country || '', [Validators.required]],
      jobTitle: [data?.jobTitle],
      phoneNumber: [data?.phoneNumber],
      additionalNames: this.fb.array([]),
    }));
  }

  initFormAddNewUser(data?: any): FormGroup {
    return (this.addNewUserForm = this.fb.group({
      originatorId: [data?.originatorId],
      firstName: [data?.firstName, [Validators.required]],
      lastName: [data?.lastName, [Validators.required]],
      organisationName: [data?.organisationName ||'The Tipperary Cheese Company Limited'],
      email: [data?.email, [Validators.required, Validators.email]],
     
    }));
  }

  private parseDate(param: string) {
    return {
      year: parseInt(moment(param).format('YYYY'), 10),
      day: parseInt(moment(param).format('DD'), 10),
      month: parseInt(moment(param).format('M'), 10),
    };
  }
}
