import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'bi bi-speedometer2',
    class: '',
    extralink: false,
    image: './assets/images/icon/dashboard.svg',
    submenu: [],
  },
  {
    path: '',
    title: 'Trade',
    icon: 'bi bi-arrow-left-right',
    class: 'collapse',
    extralink: false,
    image: './assets/images/icon/trade.svg',
    submenu: [
      {
        path: '',
        title: 'Open c-ETR Trade',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [],
      },
      {
        path: '',
        title: 'Open d-ETR Trade',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [],
      },
      {
        path: '',
        title: 'Upload Revolving d-ETR',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [],
      },
      {
        path: '',
        title: 'Open b-ETR Trade',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [],
      },
      {
        path: '',
        title: 'Upload PO',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/brightness.svg',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Add Debtor/Creditor',
    icon: 'bi bi-speedometer2',
    class: '',
    extralink: false,
    image: './assets/images/icon/add-user.svg',
    submenu: [],
  },

  {
    path: 'all-debtors-creditors',
    title: 'All Debtors/Creditors',
    icon: 'bi bi-speedometer2',
    class: '',
    extralink: false,
    image: './assets/images/icon/trade.svg',
    submenu: [],
  },

  {
    path: '',
    title: 'Transactions',
    icon: 'bi bi-arrow-left-right',
    class: 'collapse',
    extralink: false,
    image: './assets/images/icon/trade.svg',
    submenu: [
      {
        path: 'summary',
        title: 'Summary',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [],
      },
      {
        path: 'debtors',
        title: 'Debtor Statements',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [],
      },
      {
        path: 'creditors',
        title: 'Creditor Statements',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [],
      },
      {
        path: '',
        title: 'Update',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [],
      },
      {
        path: 'notices',
        title: 'Notices',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [],
      },
      {
        path: 'trades',
        title: 'All Trades',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Reports',
    icon: 'bi bi-arrow-left-right',
    class: 'collapse',
    extralink: false,
    image: './assets/images/icon/cash.svg',
    submenu: [
      {
        path: '',
        title: 'Fee Notices',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [],
      },
      {
        path: '',
        title: 'Overdue Balance',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [],
      },
      {
        path: '',
        title: 'Unallocated Payments',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [],
      },
      {
        path: '',
        title: 'Reserves Due',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [],
      },
      {
        path: '',
        title: 'Trading Report',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [],
      },
      {
        path: '',
        title: 'Outstanding d-ETR',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [],
      },
      {
        path: '',
        title: 'Settled c-ETR',
        icon: 'bi bi-subitem-icon',
        class: '',
        extralink: false,
        image: './assets/images/icon/org.svg',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Add New User',
    icon: 'bi bi-speedometer2',
    class: '',
    extralink: false,
    image: './assets/images/icon/add-user.svg',
    submenu: [],
  },
  {
    path: 'currency-configurations',
    title: 'Currency Configuration',
    icon: 'bi bi-speedometer2',
    class: '',
    extralink: false,
    image: './assets/images/icon/currency-configuration.svg',
    submenu: [],
  },
  {
    path: '/profile/view',
    title: 'View / Edit Profile',
    icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
    class: '',
    extralink: false,
    image: './assets/images/icon/person.svg',
    submenu: [], // Submenu of the submenu item if needed
  },
  {
    path: '/profile/access',
    title: 'Change Password',
    icon: 'bi bi-subitem-icon', // Provide a suitable icon for the submenu item
    class: '',
    extralink: false,
    image: './assets/images/icon/lock.svg',
    submenu: [], // Submenu of the submenu item if needed
  },
];

