import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { AgGridAngular } from '@ag-grid-community/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { GridFilterComponent } from './components/grid-filter/grid-filter.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridContainerComponent } from './components/grid-container/grid-container.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NumberWithCommasDirective } from './directives/number-with-commas.directive';
import { CustomSelectSearchFilterComponent } from './components/custom-select-search-filter/custom-select-search-filter.component';
import { FormErrorComponentComponent } from './components/form-error-component/form-error-component.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { NumberValueFormatterPipe } from './pipes/number-value-formatter.pipe';


@NgModule({
  declarations: [
      PaginatorComponent,
      GridContainerComponent,
      GridFilterComponent,
      FormErrorComponentComponent,
      NumberWithCommasDirective,
      OnlyNumberDirective,
      CustomSelectSearchFilterComponent,
      NumberValueFormatterPipe
    ],
  imports: [CommonModule, NgbModule, NgxSpinnerModule, NgxPaginationModule, AgGridAngular, NgSelectModule, BsDatepickerModule, FormsModule, ReactiveFormsModule, MatTabsModule,],
  exports: [
    NgbModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    AgGridAngular,
    NgSelectModule,
    PaginatorComponent,
    GridContainerComponent,
    GridFilterComponent,
    FormErrorComponentComponent,
    MatTabsModule,
    NumberWithCommasDirective,
    OnlyNumberDirective,
    NumberValueFormatterPipe,
  ],
})
export class SharedModule {}
