
const storedData = localStorage.getItem('userData');
const userDetails = storedData ? JSON.parse(storedData) : {};
// export const ORIGNINATOR_ID = userDetails.entityRoleId || null;
export const ORIGINATOR_ID = '11307000041'

export enum SummaryKeyInvestments {
  Payment_Out = 'bankPayment',
  Receipt_In = 'bankReceipt',
  Face_Value = 'faceValue',
  Buy_Rate = 'buyRate',
  Commission = 'commission',
  Months = 'months',
  Balance = 'balance',
  Yield = 'yield',
  Date = 'date',
}

export enum SummaryKeyFxrate {
  BuyRate = 'buyRate',
  Gbp = 'gbp',
  Usd = 'usd',
  Eonia = 'eonia',
  Libor = 'libor',
  Obfr = 'obfr',
}

export enum SummaryKeyTradeTransactions {
  PTV = 'ptv',
  SellRate = 'sellRate',
  FaceValue = 'faceValue',
  EurFacevalue = 'eurFacevalue',
  Ldc = 'ldc',
  Reserves = 'reserves',
  Outstanding = 'outstanding',
  DueOutstanding = 'dueOutstanding',
  EurPurchased = 'eurPurchased',
  Deferred = 'deferredIncome',
  EDSO = 'edso',
  RDSO = 'rdso',
  ADSO = 'adso',
  Months = 'months',
  PurchasedValue = 'purchasedValue',
}

export enum SummaryKeyBankDetails {
  bankId = 'bankId',
  bankName = 'bankName',
  sortCode = 'sortCode',
  account = 'account',
  address = 'address',
  swift = 'swift',
  iban = 'iban',
}
export enum SummaryKeyFinancialStatements {
  Payment_Out = 'bankPayment',
  Receipt_In = 'bankReceipt',
  Face_Value = 'faceValue',
  Buy_Rate = 'buyRate',
  Months = 'months',
}
