import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  updateSno(result: any[], currentPageIndex: number, queryParams: any) {
    let sStartNo = 1;
    if (currentPageIndex !== 0) {
      sStartNo = (currentPageIndex + 1) * (queryParams?.['perPage'] || 0) - ((queryParams['perPage'] || 0) - 1);
    }
    for (let i = 0; i < result.length; i++) {
      result[i].no = i + sStartNo;
    }
    return [...result];
  }
}
