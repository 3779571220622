
export const Reference_Data = [
    
    {
        id: 1,
        reference:'Transfer',
        issueDate:'2014-07-17',
        amount:'181448.18',
  
    },
    {
        id: 2,
        reference:'Settlement - Part 2                                              ',
        issueDate:'2022-05-23',
        amount:'69940.10',
  
    },
  
]