import { ColDef } from '@ag-grid-community/core';
import { numberValueFormatter } from '../../../shared/helpers/number-value-formatter';
import { blankValueFormatter } from 'src/app/shared/helpers/blank-value-formatter';
import { noValueFormatter } from 'src/app/shared/helpers/no-value-formatter';
import { TransactionUpdateComponent } from '../transaction-update.component';
import { dateFilterParams } from 'src/app/shared/helpers/date-comperator';

export const COL_DEFS: (component: TransactionUpdateComponent) => ColDef[] = (component) => [
  {
    headerName: 'No',
    field: 'no',
    width: 35,
    colId: 'no',
    hide: false,
    valueFormatter: (params) => {
      let originalValue = params.node?.rowPinned;
      if (originalValue) {
        return '';
      }
      return params.value;
    },
  },
  {
    headerName: 'Reference',
    field: 'reference',
    filter: 'agTextColumnFilter',
    width: 150,
    colId: 'reference',
    valueFormatter: noValueFormatter,
  },

  {
    headerName: 'Amount',
    field: 'amount',
    filter: 'agTextColumnFilter',
    width: 100,
    colId: 'amount',
    valueFormatter: numberValueFormatter,
  },
  {
    headerName: 'Issue Date',
    field: 'issueDate',
    filter: 'agDateColumnFilter',
    width: 85,
    colId: 'issueDate',
    cellClass: 'ag-center-aligned-cell',
    filterParams: dateFilterParams,
    valueFormatter: (params) => {
      let originalValue = params.value;
      let formattedValue = '';
      if (originalValue == '~') {
        formattedValue = '';
      } else {
        formattedValue = originalValue;
      }
      return formattedValue;
    },
  },

];




