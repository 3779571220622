import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColDef, DomLayoutType, SideBarDef } from '@ag-grid-community/core';

@Component({
  selector: 'app-grid-container',
  templateUrl: './grid-container.component.html',
  styleUrls: ['./grid-container.component.scss'],
})
export class GridContainerComponent {
  @Input() gridRowData: any[];
  @Input() gridColDefs: ColDef[];
  @Input() gridDefaultColDef: ColDef;
  @Input() hasPagination: boolean = true;
  @Input() gridPaginationPageSize: number = 100;
  @Input() sideBar: SideBarDef | string | string[] | boolean | null;
  @Input() domLayout: DomLayoutType;
  @Input() hasMasterDetail: boolean = true;
  @Input() keepDetailRows: boolean = true;
  @Input() keepDetailRowsCount: number = 2;
  @Input() detailCellRendererParams: any;
  @Input() rowHeight: number = 20;
  @Input() isGridDraggable: boolean;
  @Input() editType: 'fullRow';
  @Input() isSuppressMoveWhenRowDragging: boolean;
  @Input() classNames: string;

  @Output() gridReady = new EventEmitter<any>();
  @Output() filterChanged = new EventEmitter<any>();
  @Output() sortChanged = new EventEmitter<any>();
  @Output() rowClicked = new EventEmitter<any>();
  @Output() cellClicked = new EventEmitter<any>();
  @Output() cellValueChanged = new EventEmitter<any>();
  @Output() rowValueChanged = new EventEmitter<any>();
  @Output() rowDragEnd = new EventEmitter<any>();
  @Output() rowDragEnter = new EventEmitter<any>();
  @Output() rowDragMove = new EventEmitter<any>();
  @Output() columnVisible = new EventEmitter<any>();
  @Output() onRowClick = new EventEmitter<any>();
  getContextMenuItems = () => ['copy'];
}
