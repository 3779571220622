import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class OpenTrade_FormProvider {
  form: FormGroup;

  constructor(private fb: FormBuilder) { }

  initForm_ETR_Invoice_Upload(data?: any): FormGroup {
    return (this.form = this.fb.group({
      creditor: [ [Validators.required]],
      originator: [data?.originator || null, [Validators.required]],
      unscheduledEtr: [data?.unscheduledEtr || ''],
      paymentDiscount: [data?.paymentDiscount || '0.00'],
      ccy: [data?.ccy || ''],
      faceValue: [data?.faceValue || '', [Validators.required]],
      remainingValue: [data?.remainingValue || '0.00'],
      vatIncluded: [data?.vatIncluded || ''],
      invoiceDate: [data?.invoiceDate || null, [Validators.required]],
      expectedDate: [data?.expectedDate || null, [Validators.required]],
      pod: [data?.pod || '', [Validators.required]],
      attachPod: [data?.attachPod || ''],
      invoiceNo: [data?.invoiceNo || '', [Validators.required]],
      attachInvoice: [data?.attachInvoice || ''],
    }));
  }

  initForm_Open_A_Trade(data?: any): FormGroup {
    return (this.form = this.fb.group({
      debtor: [ [Validators.required]],
      days: [data?.days || '', [Validators.required]],
      ccy: [data?.ccy || ''],
      faceValue: [data?.faceValue || '', [Validators.required]],
      totalFaceValueofETR: [data?.totalFaceValueofETR || ''],
      numberofetr: [data?.numberofetr || ''],
      months: [data?.months || '', [Validators.required]],
      description: [data?.description || ''],
      supportingDocument: [data?.supportingDocument || ''],
      allowBuyOut: [data?.allowBuyOut || ''],
      monthlyDiscount: [data?.monthlyDiscount || '1.3980', [Validators.required]],
      buyOutDiscount: [data?.buyOutDiscount || '1.3980', [Validators.required]],
    }));
  }

  initForm_Revolving_ETR_Invoice_Upload(data?: any): FormGroup {
    return (this.form = this.fb.group({
      debtor: [ [Validators.required]],
      deliverNoA: [data?.deliverNoA || ''],
      originator: [data?.originator || null],
      days: [data?.days || '', [Validators.required]],
      ccy: [data?.ccy || ''],
      email: [data?.email || ''],
      faceValue: [data?.faceValue || '', [Validators.required]],
      remainingValue: [data?.remainingValue || '0.00'],
      invoiceDate: [data?.invoiceDate || null, [Validators.required]],
      expectedDate: [data?.expectedDate || null, [Validators.required]],
      pod: [data?.pod || '', [Validators.required]],
      attachPod: [data?.attachPod || ''],
      invoiceNo: [data?.invoiceNo || '', [Validators.required]],
      attachInvoice: [data?.attachInvoice || ''],
    }));
  }

  initForm_Open_b_ETR_Trade(data?: any): FormGroup {
    return (this.form = this.fb.group({
      expectedDate: [data?.expectedDate || null, [Validators.required]],
      friendlyName:[data?.friendlyName || ''],
      description: [data?.description || ''],
      monthlyDiscount: [data?.monthlyDiscount || '1.398', [Validators.required]],
      sellRate: [data?.sellRate || '1.398', [Validators.required]],
    }));
  }

  initForm_Upload_PO(data?: any): FormGroup {
    return (this.form = this.fb.group({
      bEtr: [data?.bEtr || null, [Validators.required]],
      debtor: [ [Validators.required]],
      ccy: [data?.ccy || '', [Validators.required]],
      poDate: [data?.poDate || null, [Validators.required]],
      faceValue: [data?.faceValue || '', [Validators.required]],
      poNumber: [data?.poNumber || '', [Validators.required]],
      pofile: [data?.pofile || ''],
    }));

  }
}
