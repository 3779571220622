<div 
     class="modal fade show custom_popup"
     tabindex="-1"
     role="dialog"
>
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header justify-content-between">
                <h5 class="modal-title">
                  Update
                </h5>
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        (click)="closeModal()"
                >
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body padding-model form-editing">
              
<div class="row transaction_popup">
    <div class="col-lg-12">
        <div class="card w-100">
            <div class="card">
                <form
                    *ngIf="transactionUpdateForm"
                    [formGroup]="transactionUpdateForm"
                    (ngSubmit)="onSubmit()"
                >
                    <div class="row">
                      <div  class="width-220 field-groups">
                        <div class="mt-3">
                          <label for="transactionType" class="form-title">
                            Transaction Type
                            <span class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.transactionType }}">
                              <i class="bi bi-question-circle"></i>
                            </span>
                          </label>
                          <div class="input-group">
                            <select class="custom-form-select border-radius-100" id="transactionType" formControlName="transactionType">
                              <option value="" disabled selected hidden>Select</option>
                              <ng-container *ngFor="let group of transactionTypes">
                                <optgroup [label]="group.label">
                                  <option *ngFor="let option of group.options" [value]="option">{{ option }}</option>
                                </optgroup>
                              </ng-container>
                            </select>

                          </div>
                        </div>
                      </div>

                      <div class="width-100 field-groups" *ngIf="transactionUpdateForm.get('transactionType')?.value">
                        <div class="mt-3">
                            <label for="credebtor" class="form-title text-nowrap"
                            >Credebtor's<span
                                class="tooltip-ng"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.credebtor }}"
                            ><i class="bi bi-question-circle"></i></span
                            ></label>
                            <select
                                class="custom-form-select border-radius-100"
                                id="credebtor"
                                formControlName="credebtor"
                            >
                            <option value="" disabled selected hidden>Select</option>
                            <option value="Cabot Financial (Ireland) Ltd - Cabot Financial (Ireland) Ltd" >Cabot Financial (Ireland) Ltd - Cabot Financial (Ireland) Ltd</option>
                                <option *ngFor="let item of credebtors" [value]="item.id">
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>  
                    </div> 

                      <div class=" width-137 field-groups" *ngIf="transactionUpdateForm.get('transactionType')?.value">
                        <div class="mt-3">
                            <label for="date" class="form-title"> Date
                                <span
                                    class="tooltip-ng ms-0"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData.date }}">
                                    <i class="bi bi-question-circle"></i>
                                </span>
                            </label>
                            <div class="input-group">
                                <input
                                    id="date"
                                    class="form-control"
                                    placeholder="YYYY-MM-DD"
                                    name="d3"
                                    formControlName="date"
                                    ngbDatepicker
                                    #d3="ngbDatepicker"
                                />
                                <button
                                    class="btn btn-outline-secondary btn-calendar-icon"
                                    (click)="d3.toggle()"
                                    type="button"
                                >
                                    <img src="./assets/images/icon/calendar-icon.svg" alt=""/>
                                </button>
                            </div>
                        </div> 
                    </div>
                  
                  <div class="width-80 field-groups"  *ngIf="transactionUpdateForm.get('transactionType')?.value && transactionUpdateForm.get('transactionType')?.value !== 'Reconcile a Debtor Account' && transactionUpdateForm.get('transactionType')?.value !== 'Reconcile a Creditor Account'">   
                    <div class="mt-3">
                        <label for="amount" class="form-title text-nowrap"> Amount
                            <span
                                class="tooltip-ng ms-0"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.amount }}"
                            ><i class="bi bi-question-circle"></i></span
                            ></label>
                        <input
                            id="amount"
                            [appOnlyNumber]="true"
                            type="text"
                            class="form-control ft-width numberText"
                            placeholder=""
                            formControlName="amount"
                          
                               />
                    </div>
                </div>

                <ng-container *ngIf="transactionUpdateForm.get('credebtor')?.value &&  (transactionUpdateForm.get('transactionType')?.value ==='Reconcile a Debtor Account' || transactionUpdateForm.get('transactionType')?.value ==='Reconcile a Creditor Account') ">
                  <div class="width-150 field-groups" *ngIf="transactionUpdateForm.get('transactionType')?.value">
                    <div class="mt-3">
                        <label for="payment" class="form-title text-nowrap"
                        >Payment(s)<span
                            class="tooltip-ng"
                            placement="bottom"
                            ngbTooltip="{{ tooltipData.payment }}"
                        ><i class="bi bi-question-circle"></i></span
                        ></label>
                        <select
                            class="custom-form-select border-radius-100"
                            id="payment"
                            formControlName="payment"
                        >
                        <option value="" disabled selected hidden>Select</option>
                        <option value="2021-07-07" >2021-07-07  EUR  4300.29</option>
                            <option *ngFor="let item of payments" [value]="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>  
                </div>

                </ng-container>
                  <div class="width-115 field-groups" *ngIf="transactionUpdateForm.get('transactionType')?.value && transactionUpdateForm.get('transactionType')?.value === 'Credit Note issued to Debtor' "  >
                    <div class="mt-3">
                        <label for="number" class="form-title text-nowrap"> Number
                            <span
                                class="tooltip-ng ms-0"
                                placement="bottom"
                                ngbTooltip="{{ tooltipData.number }}"
                            ><i class="bi bi-question-circle"></i></span
                            ></label>
                        <input
                            id="number"
                            onlyNumber
                            type="text"
                            class="form-control ft-width numberText"
                            placeholder=""
                            formControlName="number"
                          
                               />
                    </div>
                </div>

                <div class="width-100 field-groups" *ngIf="transactionUpdateForm.get('transactionType')?.value &&  transactionUpdateForm.get('transactionType')?.value === 'Reconcile a Creditor Account' && transactionUpdateForm.get('credebtor')?.value && transactionUpdateForm.get('payment')?.value ">
                  <div class="mt-3">
                      <label for="balance" class="form-title text-nowrap"
                      >Balance<span
                          class="tooltip-ng"
                          placement="bottom"
                          ngbTooltip="{{ tooltipData.balance }}"
                      ><i class="bi bi-question-circle"></i></span
                      ></label>
                    <input id="number" 
                    onlyNumber 
                    type="text" 
                    class="form-control ft-width numberText"
                    [(ngModel)]="balance"  [ngModelOptions]="{standalone: true}"
                     readonly="true"/>  
                  </div>  
              </div> 

                
                <div class="width-155 field-groups" *ngIf="transactionUpdateForm.get('transactionType')?.value && transactionUpdateForm.get('transactionType')?.value === 'Reconcile a Debtor Account' && transactionUpdateForm.get('credebtor')?.value"  >
                  <div class="mt-3">
                    <label for="deductible" class="form-title text-nowrap"> </label>
                    <div class="pl-3 form-group checkbox-sec  align-items-center">
                      <input type="checkbox" id="deductible" class="ms-2"  [(ngModel)]="isDeductibleChecked" [ngModelOptions]="{standalone: true}"/>
                        <span class="text-nowrap ms-1" (click)="isDeductibleChecked = !isDeductibleChecked; $event.preventDefault();">& Specific Deductible(s)</span> 
                    
                    </div>
                  </div>
              </div>
                <div class="width-115 field-groups" *ngIf="transactionUpdateForm.get('transactionType')?.value && transactionUpdateForm.get('transactionType')?.value === 'Reconcile a Debtor Account' && transactionUpdateForm.get('credebtor')?.value"  >
                  <div class="mt-3">
                    <label for="trailing" class="form-title text-nowrap"> </label>
                    <div class="pl-3 form-group checkbox-sec align-items-center">
                      <input type="checkbox" id="trailing" class="ms-2" [(ngModel)]="isTrailingChecked" [ngModelOptions]="{standalone: true}" />
                      <span class="text-nowrap ms-1" (click)="isTrailingChecked = !isTrailingChecked; $event.preventDefault();">& Trailing Balance</span> 
                    </div>

                  </div>
              </div>

              <div class="width-133 field-groups"  *ngIf="transactionUpdateForm.get('transactionType')?.value && transactionUpdateForm.get('transactionType')?.value === 'Reconcile a Debtor Account' && transactionUpdateForm.get('credebtor')?.value && isDeductibleChecked ">   
                <div class="mt-3">
                    <label for="specificDeductibleAmount" class="form-title text-nowrap"> Specific Deductible
                        <span
                            class="tooltip-ng ms-0"
                            placement="bottom"
                            ngbTooltip="{{ tooltipData.amount }}"
                        ><i class="bi bi-question-circle"></i></span
                        ></label>
                    <input
                        id="specificDeductibleAmount"
                        onlyNumber
                        type="text"
                        class="form-control ft-width numberText"
                        placeholder=""
                        formControlName="specificDeductibleAmount"
                      
                           />
                </div>
            </div>
              <div class="width-117 field-groups"  *ngIf="transactionUpdateForm.get('transactionType')?.value && transactionUpdateForm.get('transactionType')?.value === 'Reconcile a Debtor Account' && transactionUpdateForm.get('credebtor')?.value && isTrailingChecked ">   
                <div class="mt-3">
                    <label for="trailingBalanceAmount" class="form-title text-nowrap"> Trailing Balance
                        <span
                            class="tooltip-ng ms-0"
                            placement="bottom"
                            ngbTooltip="{{ tooltipData.amount }}"
                        ><i class="bi bi-question-circle"></i></span
                        ></label>
                    <input
                        id="trailingBalanceAmount"
                        onlyNumber
                        type="text"
                        class="form-control ft-width numberText"
                        placeholder=""
                        formControlName="trailingBalanceAmount"
                      
                           />
                </div>
            </div>



                <div class="width-100 field-groups" *ngIf="transactionUpdateForm.get('transactionType')?.value && transactionUpdateForm.get('transactionType')?.value !== 'Reconcile a Debtor Account' && transactionUpdateForm.get('transactionType')?.value !== 'Reconcile a Creditor Account'">
             
                  <div class="mt-3">
                    <div class="width-120 form-group file-upload text-center">
                      <label for="image_file" class="form-title me-4">Transaction File: </label>
                      <input type="file" id="image_file"  #fileUploader >
                    </div>
                  </div>
              </div>

              <div class="col-md-12 mt-3"  *ngIf="(transactionUpdateForm.get('transactionType')?.value && transactionUpdateForm.get('credebtor')?.value && transactionUpdateForm.get('payment')?.value &&  transactionUpdateForm.get('transactionType')?.value === 'Reconcile a Creditor Account' )">
                <label for="number" class="form-title text-nowrap"> With specific reference to
                  <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.reference }}"
              ><i class="bi bi-question-circle"></i></span
              >
                </label>
                <div class="reference">
                  <div class="ag-theme-quartz common-grids">

                <app-grid-container
                [gridRowData]="rowData"
                [gridColDefs]="colDefs"
                [hasPagination]="false"
                (gridReady)="onGridReady($event)"
                (cellClicked)="handleCellClick($event)"
            />
              </div>
              </div>
              </div>

                <div class="col-md-12"
                  *ngIf="(transactionUpdateForm.get('transactionType')?.value && transactionUpdateForm.get('transactionType')?.value !== 'Reconcile a Debtor Account' && transactionUpdateForm.get('transactionType')?.value !== 'Reconcile a Creditor Account') || ( transactionUpdateForm.get('transactionType')?.value === 'Reconcile a Creditor Account' && transactionUpdateForm.get('credebtor')?.value && transactionUpdateForm.get('payment')?.value)">
                  <div class="mt-3">
                      <label for="comments" class="form-title">Comments
                          <span
                              class="tooltip-ng ms-0"
                              placement="bottom"
                              ngbTooltip="{{ tooltipData.comments }}"
                          ><i class="bi bi-question-circle"></i></span
                          ></label>
                      <textarea
                          id="comments"
                          type="text"
                          class="form-control"
                          placeholder="Type somthing here..."
                          formControlName="comments"
                          row="8"
                          maxlength="512"
                      >
                      </textarea>
                  </div>
              </div>

                        <div class="col-12 bottom-fixed">
                            <hr />
                            <div class="d-flex justify-content-center mt-3">
                                <button [disabled]="transactionUpdateForm.invalid" type="submit" class="btn btn-light me-3 btn-custom-footer save_btn">
                                    Save
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-light btn-custom-footer cancel_btn"
                                    data-dismiss="modal"
                                    (click)="closeModal()"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
            </div>
        </div>
    </div>
</div>
