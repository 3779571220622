import { Component, ElementRef, ViewChild} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { getFormData } from 'src/app/shared/helpers/utils';
import { Add_Debtor_Creditor_FormProvider } from '../add-debtor-creditor-form-provider';
import { ORIGINATOR_ID } from 'src/app/shared/enums/common.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CURRENCIES, TYPES } from 'src/app/shared/constants/helpers.const';
import { COUNTRY_LIST } from 'src/app/shared/constants/countryList.const';
import { HttpClient } from '@angular/common/http';
import { HttpService } from 'src/app/services/http.service';
import { TooltipData_Debtor_Creditor } from '../common/constants/tooltip.data';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-debtor-creditor',
  templateUrl: './add-debtor-creditor.component.html',
  styleUrls: ['./add-debtor-creditor.component.scss'],
})
export class AddDebtorCreditorComponent {
  addForm: FormGroup;
  // todo need remove when can get from backend side
  tooltipData = TooltipData_Debtor_Creditor;
  uploadedFiles: any;
  isSubmitted = false;
  isSameAsPrimaryAddress: boolean = true;
  years: { id: string, name: string }[] = [];
  types: { id: string; name: string }[] = TYPES;
  countryList: { id: string; name: string; countryCode: string }[]=COUNTRY_LIST;
  currencyList: { id: string; name: string;}[]=CURRENCIES;
  allBankData: { id: string; name: string }[]=[];
  organisationList: { id: string; name: string;}[]=[{id:'1',name:'The Tipperary Cheese Company Limited'}];
  @ViewChild('fileUploader') fileUploader!: ElementRef;
  originatorId = ORIGINATOR_ID;
  constructor(
    private formProvider: Add_Debtor_Creditor_FormProvider,
    public activeModal: NgbActiveModal,
    private apiClient: HttpClient,
    private http: HttpService,
  ) {}

  ngOnInit(): void {
    this.addForm = this.formProvider.initForm();
    this.getYears()
    this.subscribeToTypeChange()
    this.loadDropDownsData();
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.addForm.invalid) {
      return;
    }
    let rawData = this.addForm.value;
    rawData.investorId = this.originatorId;
    rawData = getFormData(rawData);
    this.doCreate(rawData);
  }

  doCreate(data: any): void {
    delete data.address;
    // this.bankDetailsHttpService.addNewBankDetail(data).subscribe(
    //   (response) => {
    //     Swal.fire({
    //       icon: 'success',
    //       text: 'Bank Details successfully added',
    //       showConfirmButton: false,
    //       timer: 5000,
    //     });
    //     this.closeModal(true);
    //   },
    //   (error) => {
    //     console.error(error);
    //     Swal.fire({
    //       icon: 'error',
    //       text: error.message,
    //       showConfirmButton: true,
    //       confirmButtonText: 'Ok',
    //     });
    //   },
    // );
  }

  getYears(): void {
    this.years = []; // Ensure the years array is reset
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= 1950; year--) {
      this.years.push({ id: year.toString(), name: year.toString() });
    }
  }

   subscribeToTypeChange(): void {
    this.addForm.get('type')?.valueChanges.subscribe((value) => {
        this.updateBankDetailsValidators(value);
    });
  }

   isPrimaryAddress(){
   this.updateBillingAddressValidators(this.isSameAsPrimaryAddress);
  }

   updateBankDetailsValidators(type: string) {
    const controls = ['bankName', 'iban', 'swiftCode', 'accountNumber'];
    const validators = type === 'Creditor' ? [Validators.required] : [];
    controls.forEach(control => {
      this.addForm.get(control)?.setValidators(validators);
      this.addForm.get(control)?.updateValueAndValidity();
    });
  }

   updateBillingAddressValidators(isSame: boolean) {
    const controls = [
      'billingAddress1', 'billingAddress2', 'billingCity','billingState', 'billingPostCode', 'billingPhoneNumber', 'billingOtherNumber', 'billingEmail'];
    const validators = isSame ? [] : [Validators.required];
    controls.forEach(control => {
      this.addForm.get(control)?.setValidators(validators);
      this.addForm.get(control)?.updateValueAndValidity();
    });
  }
  
  closeModal() {
    this.activeModal.dismiss();
  }

   downloadTemplate() {
    let filename = 'Debtor-Experience-Upload.xlsx';
    let apiUrl = 'assets/resources/Debtor-Experience-Upload.xlsx';
    this.apiClient
    .get(apiUrl, { responseType: 'arraybuffer' })
    .subscribe((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: 'text/xlsx' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
    }

    fileChange(element: any) {
      this.uploadedFiles = element.target.files[0];
      let uploadType = 'Experience';
      return;
      this.http.upload(this.uploadedFiles, uploadType).subscribe(
        (event: any) => {
          console.log(event);
          element.target.files = null;
          this.fileUploader.nativeElement.value = null;
        },
        (error) => {
          console.error('Error:', error);
          let errorMessage = error.error.message;
          Swal.fire({
            title: 'Error',
            icon: 'error',
            html: '<div class="poprow"> ' + errorMessage + '</div>',
            showConfirmButton: true,
            confirmButtonText: 'Close',
          }).then((result) => {
            if (result.isConfirmed) {
              // Handle confirm button click
              console.log('Confirmed');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              // Handle cancel button click
              console.log('Canceled');
            }
          });
          this.fileUploader.nativeElement.value = null;
          // Handle errors
        },
      );
    }

    loadDropDownsData() {
      this.http.getBankList().subscribe((response: any) => {
        response.forEach((item: any) => {
          this.allBankData.push({
            id: item.id,
            name: item.name,
          });
        });
      });
    }

    onBankChange(event: any): void {
      const selectedId = event.target.value;
      const selectedBank = this.allBankData.find((bank) => bank.id == selectedId);
      let selectedBankName = selectedBank ? selectedBank.name : '';
      this.addForm.get('bankName')?.setValue(selectedBankName);
    }

  }
