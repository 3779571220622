import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getFormData } from 'src/app/shared/helpers/utils';
import { ORIGINATOR_ID } from 'src/app/shared/enums/common.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { TooltipData_OpenTrade } from '../common/constants/tooltip.data';
import { OpenTrade_FormProvider } from '../trade-form-provider';
import { PercentFormatter } from 'src/app/shared/helpers/percent-formatter.helper';
import { numberValueFormatter } from 'src/app/shared/helpers/number-value-formatter';
import { TradeHttpService } from 'src/app/transactions/services/trade-http.service';
import { TradeTransactionsResponseInterface } from 'src/app/shared/interfaces/trade-transactions-response.interface';
import { forkJoin, map } from 'rxjs';

@Component({
  selector: 'app-open-trade',
  templateUrl: './open-trade.component.html',
  styleUrls: ['./open-trade.component.scss'],
})
export class OpenTradeComponent {
  originatorId = ORIGINATOR_ID;
  @Input() pageTitle = '';
  addForm: FormGroup;
  remainingValue: number;
  checkedUnScheduledETR = true;
  checkedVatIncluded = true;
  isVatIncluded = false;
  isUnScheduled = false;
  isbEtr = false;
  isPod_DD = false;
  tooltipData = TooltipData_OpenTrade;
  uploadedFiles: any;
  isSubmitted = false;
  private queryParams: Partial<any> = {
    pageIndex: 0,
    perPage: 50,
    sort: ['-tradedDate'],
    originatorId: this.originatorId,
  };
  years: { id: string, name: string }[] = [];

  creditorList: any[] = [];
  originatorList: any[] = [
    { id: 1, originatorId: 2016110400016, date: '2016-11-04', ccy: 'EUR', value: '1551.90', unScheduled: true },
    { id: 2, originatorId: 2016110400039, date: '2016-05-25', ccy: 'EUR', value: '-16331.04', unScheduled: false },
    { id: 3, originatorId: 2016110400119, date: '2016-12-13', ccy: 'EUR', value: '3.70', unScheduled: true },
    { id: 4, originatorId: 2016110400128, date: '2016-12-19', ccy: 'EUR', value: '4795.60', unScheduled: true },
    { id: 5, originatorId: 2016110400149, date: '2016-01-31', ccy: 'EUR', value: '128', unScheduled: false },
    { id: 6, originatorId: 2016110400016, date: '2016-05-06', ccy: 'EUR', value: '11589.25', unScheduled: true },
  ];
  originatorList2 = [
    { id: '1', label: '02.06.17 - 2017-05-29' },
    { id: '2', label: 'Friday 30.06.17 - 2017-06-26' },
    { id: '0', label: 'Without b-ETR' }
  ];
  debtorList: any[] = [
    // { id: 1, name: '11307000041 Buy-Out ', debtorId: 20130821000137, days: 45, faceValue: 180, remainingValue: 18000, ccy: 'EUR', email: 'info@tippcheese.ie', isbEtr: false },
  ];

  bETRList: { id: number; name: string; originatorId: number; }[] = [
    { id: 1, name: 'English Funny', originatorId: 2017111300349, },
    { id: 2, name: 'PRM Ireland', originatorId: 2018091801008, },
    { id: 3, name: 'My Test', originatorId: 202111170363, },
    { id: 4, name: 'Test', originatorId: 202111180364, },
  ];
  emails: { email: string }[] = [];

  @ViewChild('fileUploader') fileUploader!: ElementRef;
  constructor(
    private formProvider: OpenTrade_FormProvider,
    public activeModal: NgbActiveModal,
    private http: HttpService,
    private tradeTransactionsHttpService: TradeHttpService,
  ) { }

  ngOnInit(): void {
    this.getAllDebtorsCreditors(this.queryParams);
    switch (this.pageTitle) {
      case 'ETR Invoice Upload':
        this.addForm = this.formProvider.initForm_ETR_Invoice_Upload();
        break;
      case 'Open A Trade':
        this.addForm = this.formProvider.initForm_Open_A_Trade();
        break;
      case 'Revolving ETR Invoice Upload':
        this.addForm = this.formProvider.initForm_Revolving_ETR_Invoice_Upload();
        break;
      case 'Open b-ETR Trade':
        this.addForm = this.formProvider.initForm_Open_b_ETR_Trade();
        break;
      case 'Upload PO':
        this.addForm = this.formProvider.initForm_Upload_PO();
        break;
    }
    this.getYears();
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.addForm.invalid) {
      return;
    }
    let rawData = this.addForm.value;
    rawData.investorId = this.originatorId;
    rawData = getFormData(rawData);
    this.doCreate(rawData);
  }

  doCreate(data: any): void {
    delete data.address;
  }

  getYears(): void {
    this.years = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= 1950; year--) {
      this.years.push({ id: year.toString(), name: year.toString() });
    }
  }

  

  closeModal() {
    this.activeModal.dismiss();
  }

  fileChange(element: any) {
    this.uploadedFiles = element.target.files[0];
    const uploadType = 'Experience';
    return;
  }


  formatPaymentDiscount(): void {
    const formattedValue = PercentFormatter(this.addForm.value.paymentDiscount, false);
    this.addForm
      .get('paymentDiscount')
      ?.patchValue(
        +this.addForm.value.paymentDiscount > 99.99 || +this.addForm.value.paymentDiscount < 0
          ? +this.addForm.value.paymentDiscount
          : +formattedValue === 0
            ? '0.00'
            : formattedValue,
        { emitEvent: false },
      );
  }

  onFaceValueChange(type: string): void {
    if (type == 'open_a_trade') {
      const faceValue = Number(this.addForm.value.faceValue);
      const remainingValue = Number(parseFloat(this.addForm.value.remainingValue.replace(/,/g, '')));
      if (!isNaN(remainingValue)) {
        if (remainingValue) {
          const formattedRemainingValue = numberValueFormatter({ value: Number(remainingValue - faceValue) });
          this.addForm.get('remainingValue')?.patchValue(formattedRemainingValue, { emitEvent: false });
        }
      }
      if (faceValue === 0 && !isNaN(remainingValue)) {
        const creditorId = this.addForm.value.creditor;
        const selectedCreditor = this.creditorList.find((item) => item.id == creditorId);
        if (selectedCreditor?.remainingValue) {
          const formattedRemainingValue = numberValueFormatter({ value: Number(selectedCreditor?.remainingValue) });
          this.addForm.get('remainingValue')?.patchValue(formattedRemainingValue, { emitEvent: false });
        }
      }
    }
    else if (type == 'revolving') {
      const faceValue = Number(this.addForm.value.faceValue);
      const remainingValue = Number(parseFloat(this.addForm.value.remainingValue.replace(/,/g, '')));
      if (faceValue != 0 && !isNaN(remainingValue)) {
        if (remainingValue) {
          const formattedRemainingValue = numberValueFormatter({ value: Number(remainingValue - faceValue) });
          this.addForm.get('remainingValue')?.patchValue(formattedRemainingValue, { emitEvent: false });
        }
      }
      if (faceValue === 0 && !isNaN(remainingValue)) {
        const debtorId = this.addForm.value.debtor;
        const selectedDebtor = this.debtorList.find((item) => item.id == debtorId);
        if (selectedDebtor?.remainingValue) {
          const formattedRemainingValue = numberValueFormatter({ value: Number(selectedDebtor?.remainingValue) });
          this.addForm.get('remainingValue')?.patchValue(formattedRemainingValue, { emitEvent: false });
        }

      }
    }

  }

  onCreditorChange(event: any) {
    if(event ==undefined){
      this.addForm.get('ccy')?.setValue('');
      this.addForm.get('remainingValue')?.setValue('0.00');
      return;
    }
    const selectedId = event.id;
    const selectedData = this.creditorList.find((item) => item.id == selectedId);
     if (selectedData) {
      this.isVatIncluded = selectedData.vatIncluded;
      this.addForm.get('ccy')?.setValue(selectedData.ccy);
      if (selectedData.remainingValue) {
        const formattedValue = numberValueFormatter({ value: Number(selectedData.remainingValue) });
        this.addForm.get('remainingValue')?.setValue(formattedValue);
      }

    } else {
      this.isVatIncluded = false
      this.addForm.get('ccy')?.setValue('');
      this.addForm.get('remainingValue')?.setValue('0.00');
    }
  }

  onDebtorChange(event: any, type: string) {   
    if(event ==undefined){
      this.addForm.get('ccy')?.setValue('');
        this.addForm.get('days')?.setValue('');
        this.addForm.get('faceValue')?.setValue('');
      return;
    }
    const selectedId = event.id;
    const selectedData = this.debtorList.find((item) => item.id == selectedId);
    if (type == 'open_a_trade') {
      if (selectedData) {
        this.addForm.get('ccy')?.setValue(selectedData.ccy);
        this.addForm.get('days')?.setValue(selectedData.days);
        this.addForm.get('faceValue')?.setValue(selectedData.faceValue);
      } else {
        this.addForm.get('ccy')?.setValue('');
        this.addForm.get('days')?.setValue('');
        this.addForm.get('faceValue')?.setValue('');
      }
    }
    else if (type == 'revolving') {
      this.emails = [];
      if (selectedData) {
        this.isbEtr = selectedData.isbEtr;
        this.addForm.get('ccy')?.setValue(selectedData.ccy);
        this.addForm.get('days')?.setValue(selectedData.days);
        const formattedValue = numberValueFormatter({ value: Number(selectedData.remainingValue) });
        this.addForm.get('remainingValue')?.setValue(formattedValue);
        if (selectedData.email) {
          this.emails.push({ email: selectedData.email });
          this.addForm.get('email')?.setValue(selectedData.email);
        }
      } else {
        this.addForm.get('ccy')?.setValue('');
        this.addForm.get('days')?.setValue('');
        this.addForm.get('remainingValue')?.setValue('');
        this.emails = [];
        this.addForm.get('email')?.setValue('');
        this.isbEtr = false;
      }
    }
    else if (type == 'upload_po') {
      if (selectedData) {
        this.addForm.get('ccy')?.setValue(selectedData.ccy);
      } else {
        this.addForm.get('ccy')?.setValue('');
      }
    }

  }

  onOriginatorChange(event: any, type: string) {
    if (type == 'etr_invoice_upload') {
      const selectedId = event?.id;
      const selectedData = this.originatorList.find((item) => item.id == selectedId);
      if (selectedData) {
        this.isUnScheduled = selectedData.unScheduled;
      } else {
        this.isUnScheduled = false;
      }
    }
    else if (type == 'revolving') {
      const selectedId = event.target.value;
      if (selectedId == 0) {
        this.isPod_DD = false;
        this.addForm.get('pod')?.setValue('');
      }
      else {
        this.isPod_DD = true;
      }
    }
  }

  private getAllDebtorsCreditors(queryparams: any): void {
    const storedCreditorList = JSON.parse(localStorage.getItem('uniqueCreditorList') || '[]');
    this.creditorList = storedCreditorList;
    const storedDebtorList = JSON.parse(localStorage.getItem('uniqueDebtorList') || '[]');
    this.debtorList = storedDebtorList;
    queryparams.originatorId = this.originatorId.toLocaleString();
    const singleRequestParams = { ...queryparams, perPage: 1 };
    this.tradeTransactionsHttpService.getTradeTransactionsDataMultipleCall(singleRequestParams).subscribe({
      next: (response) => {
        const totalRecords = response.meta.total;
        const recordsPerPage = 3000;
        const totalPages = Math.ceil(totalRecords / recordsPerPage);

        const requests = Array.from({ length: totalPages }, (_, index) => {
          const requestParams = { ...queryparams, pageIndex: index, perPage: recordsPerPage };
          return this.tradeTransactionsHttpService.getTradeTransactionsDataMultipleCall(requestParams);
        });
        forkJoin(requests).pipe(
          map((responses: any[]) => {
            return responses.reduce((acc, response) => {
              return [...acc, ...response.items];
            }, []);
          })
        ).subscribe({
          next: (rowData: any[]) => {
            const formatData = (data: any[], entityKey: string, idKey: string) => {
              return data.map((ele: TradeTransactionsResponseInterface | any) => {
                ele[`${entityKey}Name`] = ele[entityKey]?.entity?.name || null;
                return ele;
              }).filter(ele => !!ele[idKey]);
            };
            const sortedCreditors = rowData.sort((a, b) => new Date(b.tradedDate).getTime() - new Date(a.tradedDate).getTime());
            const uniqueCreditors = Array.from(new Map(formatData(sortedCreditors, 'creditor', 'creditorId').map(ele => [ele.creditorId, ele])).values());
            this.creditorList = uniqueCreditors.filter(item => item.creditorName)
              .map(item => ({
                id: item.creditorId,
                name: item.creditorName,
                ccy: item.ccy,
                remainingValue: 0.00,
                vatIncluded: !!item.vatIncluded,
              }));
            this.creditorList = this.sortByName(this.creditorList);
            const sortedDebtors = rowData.sort((a, b) => new Date(b.tradedDate).getTime() - new Date(a.tradedDate).getTime());
            const uniqueDebtors = Array.from(new Map(formatData(sortedDebtors, 'debtor', 'debtorId').map(ele => [ele.debtorId, ele])).values());
            this.debtorList = uniqueDebtors.filter(item => item.debtorName)
              .map(item => ({
                id: item.debtorId,
                name: item.debtorName,
                faceValue: item.faceValue,
                ccy: item.ccy,
                remainingValue: 0.00,
                vatIncluded: !!item.vatIncluded,
              }));
            this.debtorList = this.sortByName(this.debtorList);

            localStorage.setItem('uniqueCreditorList', JSON.stringify(this.creditorList));
            localStorage.setItem('uniqueDebtorList', JSON.stringify(this.debtorList));
          },
        });
      },
    });
  }

  private sortByName(items: any[]) {
    return items.sort((a, b) => {
      const nameA = a.name?.trim().toLowerCase() || '';
      const nameB = b.name?.trim().toLowerCase() || '';
      return nameA?.localeCompare(nameB);
    });
  }

customSearch(term: string, item: any): boolean {
    if (!term) {
      return true;
    }
    const searchTerm = term.toLowerCase();
    const nameMatches = item.name.toLowerCase().includes(searchTerm);
    const idMatches = item.id.toString().toLowerCase().includes(searchTerm);
    return nameMatches || idMatches;
  }
  
  
}
