import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, share, Subject, switchMap, take } from 'rxjs';
import { API_URL } from 'src/app/app.module';
import { HttpService } from 'src/app/services/http.service';
import { Summary } from 'src/app/shared/interfaces/base-response-interface';
import { DistinctValuesResponse } from 'src/app/shared/interfaces/distinct-values-response.interface';
import { TradeTransactionsQueryParams } from 'src/app/shared/interfaces/trade-transactions-query-params';
import { TradeTransactionsResponseInterface } from 'src/app/shared/interfaces/trade-transactions-response.interface';


@Injectable({ providedIn: 'root' })
export class TradeHttpService extends HttpService {
  private requestTrigger$ = new BehaviorSubject<Partial<TradeTransactionsQueryParams> | null>(null);
  private responseSubject$ = new Subject<TradeTransactionsResponseInterface>();
  private summaryTrigger$ = new BehaviorSubject<Partial<TradeTransactionsQueryParams> | null>(null);
  private summarySubject$ = new Subject<Summary>();
  constructor(
    @Inject(API_URL) baseUrl: string,
    override http: HttpClient,
  ) {
    super(baseUrl, http);
    this.setupRequestHandler('etr', this.responseSubject$);
    this.setupRequestHandler('etr/summary', this.summarySubject$);
  }

  getTradeTransactionsData(params: Partial<TradeTransactionsQueryParams>): Observable<TradeTransactionsResponseInterface> {
    this.requestTrigger$.next(params);
    return this.responseSubject$.pipe(take(1));
  }

  getEtrSummary(params: Partial<TradeTransactionsQueryParams>): Observable<Summary> {
    this.summaryTrigger$.next(params);
    return this.summarySubject$.pipe(take(1));
  }

  getTradeTransactionsDataMultipleCall(params: Partial<TradeTransactionsQueryParams>): Observable<TradeTransactionsResponseInterface> {
    return this.http.get<TradeTransactionsResponseInterface>(`${this.baseUrl}etr`, { params: this.queryCleaner(params)});
  }

  getEtrSummaryMultipleCall(params: Partial<TradeTransactionsQueryParams>): Observable<Summary> {
    return this.http.get<Summary>(`${this.baseUrl}etr/summary`, { params: this.queryCleaner(params)});
  }

  getCurrencyFilterData(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}currencies`);
  }

  getEtrFilterDistinctValues(): Observable<DistinctValuesResponse> {
    return this.http.get<DistinctValuesResponse>(`${this.baseUrl}etr/filter-distinct-values`);
  }



  private setupRequestHandler(endpoint: string, subject: Subject<any>) {
    this.requestTrigger$
      .pipe(
        switchMap((params) => {
          if (params === null) return of([]); 
          return this.http.get(`${this.baseUrl}${endpoint}?`, { params: this.queryCleaner(params) }).pipe(
            catchError((error) => {
              subject.error(error); 
              return of(null); 
            })
          );
        }),
        share(),
      )
      .subscribe(subject);
  }

}
