<div class="mt-1 loan-agreement" >
  <table class="loan-agreement-table" >
    <tbody>
      <tr>
        <td colspan="2" class="width-100per" align="center">Originator ID :
          <b>11307000041</b>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="width-100per" align="center"><b>ETR Loan Agreement </b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b> </b></td>
        <td class="width-94per"><b>THIS LOAN AGREEMENT</b> is made on
          2024-10-04</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per"><b>BETWEEN</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">(1)</td>
        <td class="width-94per">The Tipperary Cheese Company Limited with
          registered company number IE386031, having its registered address at Two-Mile-Borris,
          Thurles, Thurles, Thurles, Tipperary, Ireland,
          and email address infotippcheese.ie &nbsp; (the "<b>Borrower</b>").</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">(2)&nbsp;</td>
        <td class="width-100per"><b>CREDEBT EXCHANGE LIMITED</b>,
          a private company with limited liability incorporated in Ireland (registered
          number 501210), having its registered address at 15A Baggotrath Place, 15 - 16 Lower
          Baggot Street, Dublin D02 NX49, Ireland (the "<b>Original Lender</b>").</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b> </b></td>
        <td class="width-94per"><b>BACKGROUND</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">The Original Lender has agreed to provide a
          loan to the Borrower in the aggregate principal amount of EUR
          (the "<b>Loan</b>"), upon and subject to the terms and
          conditions of this Agreement.
        </td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per"><b>IT IS AGREED</b> as follows:</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>1. </b></td>
        <td class="width-94per"><b>Interpretation</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">1.1</td>
        <td class="width-94per"><b>Definitions</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">In this Agreement, unless otherwise
          specified:</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">"<b>Agreement</b>" means this loan agreement;
        </td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">"<b>Business Day</b>" means a day (other than
          a Saturday or Sunday) on which clearing banks in Dublin are open for business;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">"<b>EUR</b>", "<b>Euro</b>", "<b>Euros</b>",
          "<b>euro</b>", "<b>euros</b>" and "<b>€</b>" mean the currency introduced at the start
          of the third stage of European economic and monetary union pursuant to the Treaty
          establishing the European Community, as amended from time to time;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">"<b>Event of Default</b>" has the meaning
          given to that term in Clause 8;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">"<b>GBP</b>", "<b>Pounds</b>",
          "<b>Sterling</b>", and "<b>£</b>" denote the lawful currency of the United Kingdom;
        </td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">"<b>Lender</b>" means:</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per" class="height-19px"></td>
        <td class="width-100per" class="height-19px">(a) the Original Lender;
          and</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(b) any person (legal or natural) to whom the
          Original Lender has transferred or assigned its rights hereunder,</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">which in each case has not ceased to be a
          Party;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">"<b>Loan</b>" shall have the meaning given to
          it in under Background above;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">"<b>Party</b>" means a party to this
          Agreement;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">"<b>Repayment Date</b>" shall have the
          meaning given to it in Clause 4.1;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">"<b>Tax</b>" means any tax, levy, impost,
          duty or other charge, fee, deduction or withholding of a similar nature (including any
          penalty or interest payable in connection with the failure to pay, or delay in paying,
          any of these);</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">"<b>Tax Deduction</b>" means a deduction or
          withholding for, or on account of, Tax from a payment under this Agreement or with
          respect to the Loan; and</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">"<b>USD</b>", "<b>Dollar</b>",
          "<b>Dollars</b>", and "<b>$</b>" denote the lawful currency of the United States of
          America.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">1.2</td>
        <td class="width-94per">In this Agreement, unless otherwise specified:
        </td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(a) references to Clauses and Recitals are to
          Clauses and Recitals of this Agreement;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(b) any reference to a "<b>day</b>" or a
          "<b>Business Day</b>" shall mean a period of 24 hours running from midnight to
          midnight;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(c) references to times are to Dublin,
          Ireland time;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(d) any phrase introduced by the terms
          "<b>including</b>", "<b>include</b>", "<b>in particular</b>" or other similar
          expression shall be construed as illustrative and shall not limit the sense or meaning
          of the words preceding those terms;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(e) references to the singular include the
          plural and vice versa; and</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(f) references to the masculine include the
          feminine and vice versa.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">1.3</td>
        <td class="width-94per">All headings and titles are inserted for
          convenience only. They are to be ignored in the interpretation of this Agreement.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>2. </b></td>
        <td class="width-94per"><b>Loan</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">By its countersignature below, the Borrower
          acknowledges that on the date of this Agreement the Lender has advanced the Loan to
          the Borrower on the terms of this Agreement.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>3. </b></td>
        <td class="width-94per"><b>Currency of the Loan</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">The Loan, interest payable on the Loan and
          all other amounts due under this Agreement shall be denominated in EUR/GBP/USD.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>4. </b></td>
        <td class="width-94per"><b>Repayment</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">4.1</td>
        <td class="width-94per">The principal amount of the Loan then
          outstanding is repayable in full, together with accrued interest, on the first
          anniversary† of this Agreement (or, if such date is not a Business Day, on the next
          Business Day thereafter) (the "Repayment Date").</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">4.2</td>
        <td class="width-94per">The Lender may, at any time prior to the
          Repayment Date, require repayment of the principal amount of the Loan then
          outstanding, together with accrued interest, upon giving not less than 10 Business
          Days' notice in writing to the Borrower.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          4.3</td>
        <td class="width-94per">The Borrower shall repay the principal amount
          of the Loan in the amounts and on the dates indicated in the Schedule, under the
          heading "Principal Repayments".</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>5. </b></td>
        <td class="width-94per"><b>Interest</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">5.1</td>
        <td class="width-94per">Interest shall accrue from day to day on the
          principal amount outstanding of the Loan at a rate set out in the Schedule under
          opposite the heading "Interest Rate".</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">5.2</td>
        <td class="width-94per">Interest shall be paid monthly in arrears upon
          demand by the Lender, calculated on the basis of actual days elapsed and on a 360 day
          year.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>6. </b></td>
        <td class="width-94per"><b>Representations,
            Warranties and Undertakings</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">6.1</td>
        <td class="width-94per">The Lender represents and warrants to the
          Borrower that the Lender has all requisite corporate power and authority to execute,
          deliver and perform its obligations under this Agreement.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">6.2</td>
        <td class="width-94per">The Borrower represents, warrants and
          undertakes to the Lender as follows:</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(a) that the Borrower has been duly
          incorporated and is validly existing under the law of its jurisdiction of
          incorporation with full power and authority to conduct its business and to conduct the
          business it now conducts and/or proposes to conduct;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(b) the Borrower has all requisite corporate
          power and authority to authorise and execute this Agreement and to deliver and perform
          all obligations and undertakings under the Agreement, without the approval of its
          shareholders or any other person;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(c) this Agreement has been duly authorised
          and executed by the Borrower and is a valid and binding obligation of the Borrower
          enforceable in accordance with its terms;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(d) the Borrower is able to pay its debts as
          they fall due and no corporate action or other steps have been taken or legal
          proceedings started for its winding up, dissolution or re-organisation or insolvency
          or similar proceedings or for the appointment of a liquidator, receiver,
          administrator, conservator, custodian, trustee or similar officer of it or of any or
          all of its revenues and assets;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(e) the execution and delivery of this
          Agreement is not inconsistent with the Borrower's constitutional documents or any
          regulation or by-law adopted by the Borrower, and does not and will not constitute a
          default under, any indenture, mortgage, contract or other instrument to which the
          Borrower is a party or by which it is bound; and</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(f) it is capable of assessing the merits of
          and understanding (on its own behalf or through independent professional advice), and
          understands and accepts, the terms, conditions of, and notes of entering into, this
          Agreement.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">6.3</td>
        <td class="width-94per">The Borrower undertakes shall promptly after
          becoming aware of the occurrence thereof notify the Lender of:</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(a) any Event of Default or any condition,
          event or act which would after the execution of the Agreement constitute an Event of
          Default; or</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(b) any breach of its representations,
          warranties or undertakings contained in this Agreement to which it is a party.</td>
      </tr>
      <tr>
        <td class="width-6per" class="height-19px"></td>
        <td class="width-94per" class="height-19px"></td>
      </tr>
      <tr>
        <td class="width-6per"><b>7. </b></td>
        <td class="width-94per"><b>Security</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">The Loan together with interest and other
          monies due or to become due under this Agreement shall be secured by a pledge or
          charge in a form satisfactory to the Lender over the Borrower's stock.&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>
            8. </b></td>
        <td class="width-94per"><b>Events of Default</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          8.1</td>
        <td class="width-94per">Each of the following describes circumstances
          which constitute an event of default ("<b>Event of Default</b>") for the purposes of
          this Agreement.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(a) the Borrower fails to pay on the due date
          for payment (otherwise than by reason of temporary technical or administrative
          difficulties in making payment) any sum due from it under this Agreement; or</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(b) the Borrower is unable to pay its debts
          as they fall due (or, in the opinion of the Lender, is or could be deemed by law or by
          a court to be unable to pay its debts), stops, suspends, or threatens to stop or
          suspend payment of all or any part of its indebtedness, commences negotiations with
          any one or more of its creditors with a view to the general re-adjustment or
          re-scheduling of all or any part of its indebtedness or makes a general assignment for
          the benefit of, or composition with, its creditors or a moratorium is agreed or
          declared in respect of, or affecting, all or any part of its indebtedness; or</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(c) if any steps are taken for the
          winding-up, dissolution or re-organisation of the Borrower (other than for the
          purposes of reconstruction or amalgamation whilst solvent on terms which have been
          previously approved by the Lender in writing) or for the appointment of a receiver or
          similar officer or insolvency or similar proceedings are commenced against the
          Borrower or the Borrower is otherwise unable to or fails to pay its debts as they fall
          due; or</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(d) the Borrower ceases carrying on,
          threatens to cease carrying on, or disposes of its business or a material part of its
          business; or</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(e) the Borrower takes any corporate or other
          action or other steps are taken or legal proceedings are started for its winding up,
          dissolution, re-organisation, insolvency or similar proceedings or for the appointment
          of a liquidator, receiver, administrator, conservator, custodian, trustee, bankruptcy
          official, or similar officer of it or of any or all of its revenues and assets; or
        </td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(f) any material adverse change occurs or
          appears in the reasonable opinion of the Lender, to be likely to occur, in the
          trading, financial or other affairs of the Borrower.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          8.2</td>
        <td class="width-94per">At any time after the occurrence of an Event
          of Default the Lender may give notice to the Borrower stating (and shall specify which
          Event of Default has occurred) that the Loan and all interest accrued and other sums
          payable under this Agreement is immediately due and payable by the Borrower.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>
            9. </b></td>
        <td class="width-94per"><b>No Set off</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          9.1</td>
        <td class="width-94per">All payments by the Borrower under this
          Agreement shall be made without set-off or counterclaim, free and clear of any
          deduction or withholding of any kind, unless a Tax Deduction is required by law.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          9.2</td>
        <td class="width-94per">Where a payment is made which is less than the
          total amount of principal and interest immediately due at that time, the Lender shall
          be entitled to allocate it as between principal, and interest, up to the amount of
          each item then immediately due, in its absolute discretion.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          9.3</td>
        <td class="width-94per">The Lender shall be entitled to set-off any
          amounts (whether actual or contingent) owing or payable to it with respect to the Loan
          or under this Agreement against any amount owing or payable to it by the Borrower.
        </td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>
            10. </b></td>
        <td class="width-94per"><b>Tax</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          10.1</td>
        <td class="width-100per">The Borrower shall make all payments to be
          made by it without any Tax Deduction, unless a Tax Deduction is required by law.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          10.2</td>
        <td class="width-100per">The Borrower shall promptly upon becoming
          aware that it must make a Tax Deduction (or that there is any change in the rate or
          the basis of a Tax Deduction) notify the Lender accordingly.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          10.3</td>
        <td class="width-100per">The Borrower and the Lender shall use all
          reasonable efforts to ensure that no such withholding shall be required as a matter of
          law and understand that at the date hereof, where the Lender is a body corporate:</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          &nbsp;</td>
        <td class="width-100per">(i) which advances money in the ordinary
          course of a trade which includes the lending of money and makes these loans from its
          offices in Ireland; and</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          &nbsp;</td>
        <td class="width-100per">(ii) in whose hands any interest payable in
          respect of monies so advanced is taken into account in computing the trading income of
          such body corporate; and</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          &nbsp;</td>
        <td class="width-100per">(iii) which has made the appropriate
          notifications under section 246(5)(a) Taxes Consolidation Act 1997, as amended to the
          Revenue Commissioners and the Borrower,</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          &nbsp;</td>
        <td class="width-100per">then this may provide a basis under Irish law
          to avoid a Tax Deduction to be made.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          10.4</td>
        <td class="width-100per">If the Borrower is required to make a Tax
          Deduction, the Borrower shall make that Tax Deduction and any payment required in
          connection with that Tax Deduction within the time allowed and in the minimum amount
          required by law.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">
          10.5</td>
        <td class="width-100per">Within thirty days of making either a Tax
          Deduction or any payment required in connection with that Tax Deduction, the Borrower
          shall deliver to the Lender evidence reasonably satisfactory to the Lender of the
          basis on which the Tax Deduction has been made, and that the Tax Deduction has been
          made or (as applicable) any appropriate payment paid to the relevant taxing authority
        </td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>11. </b></td>
        <td class="width-94per"><b>Payments</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">All amounts due from the Borrower hereunder
          shall be paid to the Lender in EUR and in cleared funds by direct bank transfer to the
          nominated bank account of the Lender (or such other account as the Lender may specify
          to the Borrower in writing), for value not later than 12 noon on the due date and free
          and clear of any deduction, withholding, set-off or counterclaim, save as required by
          law.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>12. </b></td>
        <td class="width-94per"><b>Additional Costs</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">The Borrower shall, from time to time on
          demand of the Lender, reimburse the Lender for all reasonable costs and expenses
          (including legal fees) together with VAT thereon incurred by it in connection with the
          preservation and/or enforcement of the Lender’s rights under this Agreement and/or any
          amendment, variation of, novation of, supplement to, or waiver in respect of, this
          Agreement.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>13. </b></td>
        <td class="width-94per"><b>Assignment</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">13.1</td>
        <td class="width-94per">Assignments and transfers by the Lenders.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(a) A Lender may:</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(i) assign any of its rights; or</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(ii) transfer by novation any of its rights
          and obligations</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per"> to any person (legal or natural) who is not
          a consumer (for the purposes of applicable Irish consumer law or regulation).</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">(b) The consent of the Borrower is not
          required for an assignment or transfer by the Lender</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">13.2</td>
        <td class="width-94per">Assignments and transfer by the Borrower</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">The Borrower may not assign any of its rights
          or transfer any of its rights or obligations under the Finance Documents.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>14. </b></td>
        <td class="width-94per"><b>Notices</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">Every notice or demand to be given hereunder
          shall be in writing and shall be expressed to be a notice given hereunder and shall be
          deemed duly given upon the Business Day on which (or the first Business Day after the
          date on which) it is left at:</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">14.1</td>
        <td class="width-94per">the email address of the party as set out
          above; or</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">14.2</td>
        <td class="width-94per">the address of the party as set out above; or
        </td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">14.3</td>
        <td class="width-94per">such other address as such party shall have
          previously communicated by notice to the party giving such first mentioned notice or
          demand.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">For so long as the Original Lender is the
          Lender, notices may also be given by the posting them to the Exchange Trade Centre
          Reports &amp; Notifications system provided to the Borrower by the Original Lender.
        </td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>15. </b></td>
        <td class="width-94per"><b>No Partnership</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">Nothing in this Agreement and no action taken
          by the parties pursuant to this Agreement shall constitute, or be deemed to
          constitute, a partnership, association, joint venture or other co-operative entity
          between any of the parties.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>16. </b></td>
        <td class="width-94per"><b>Variation</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">This Agreement may only be varied in writing
          signed by each of the parties.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>17. </b></td>
        <td class="width-94per"><b>Counterparts</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">17.1</td>
        <td class="width-94per">This Agreement may be executed in any number
          of counterparts, and by the parties on separate counterparts, but shall not be
          effective until each party has executed at least one counterpart.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">17.2</td>
        <td class="width-94per">Each counterpart shall constitute an original
          of this Agreement, but all the counterparts shall together constitute but one and the
          same instrument.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>18. </b></td>
        <td class="width-94per"><b>Electronic Charge</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">This Agreement and any document contemplated
          by it shall be validly executed by the electronic signature of the Parties as
          exchanged on the Original Lender's Exchange Trade Centre Reports &amp; Notification
          System.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>19. </b></td>
        <td class="width-94per"><b>Waiver, Release and Remedies</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">A failure to exercise or delay in exercising
          a right or remedy provided by this Agreement or by law does not constitute a waiver of
          the right or remedy or a waiver of other rights or remedies. No single or partial
          exercise of a right or remedy provided by this Agreement or by law prevents further
          exercise of the right or remedy or the exercise of another right or remedy.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per"><b>
            20. </b></td>
        <td class="width-94per"><b>Governing Law and Jurisdiction</b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">This Agreement and any non-contractual
          obligations arising out of or in connection with this Agreement shall be governed by
          and construed in accordance with Irish law. The courts of Ireland shall have
          non-exclusive jurisdiction to hear any disputes or matters that arise out of or in
          connection with this Agreement.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">This Agreement has been entered into on the
          date stated at the beginning of the Agreement.</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per"></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">
          <hr width="75%">
        </td>
      </tr>
      <tr>
        <td class="width-6per"><b>SCHEDULE </b></td>
        <td class="width-94per"><b></b></td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">Digitally signed by The Borrower On :
          2024-10-04</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">Interest Rate : 1.398% per month</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">Principal Repayment Date :
        </td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">Principal Repayment Amount : EUR

        </td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-100per">© Copyright Credebt Exchange Limited 2024
        </td>
      </tr>
      <tr>
        <td class="width-6per">&nbsp;</td>
        <td class="width-94per">&nbsp;</td>
      </tr>
    </tbody>
  </table>
</div>