import { Component, Input, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getFormData } from 'src/app/shared/helpers/utils';
import { TransactionFormProvider } from '../transaction-form-provider';
import { NgbActiveModal, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { ORIGINATOR_ID } from 'src/app/shared/enums/common.enum';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { COL_DEFS } from './constants/table.columns.const';
import { ColDef, GridReadyEvent } from '@ag-grid-community/core';
import { Transaction_Types } from 'src/app/shared/constants/helpers.const';
import { CommonService } from 'src/app/services/common.service';
import { debounceTime } from 'rxjs';
import { Reference_Data } from '../services/transaction-update-reference.data';

@Component({
  selector: 'app-transaction-update',
  templateUrl: './transaction-update.component.html',
  styleUrls: ['./transaction-update.component.scss'],
})
export class TransactionUpdateComponent {
  colDefs: ColDef[] = COL_DEFS(this);
  rowData: any[] = [];
  transactionUpdateForm: FormGroup;
  @Input() transactionType: string;
  gridApi: any;
  params: any;
  // todo need remove when can get from backend side
  tooltipData = {
    transactionType: 'Transaction Type',
    credebtor: 'Credebtor Name',
    date: 'Date',
    amount: 'Amount',
    number: 'Number',
    transactionFile: 'TransactionFile',
    comments: 'Comments',
    payment: 'Payment',
    trailingBalance: 'Trailing Balance',
    specificDeductible: 'Specific Deductible',
    reference: 'Reference',
    balance: 'Balance',
  };
  isSubmitted = false;
  transactionTypes = Transaction_Types
  balance = 0
  isTrailingChecked = false;
  isDeductibleChecked = false;
  credebtors: { id: string; name: string }[] = [];
  payments: { id: string; name: string }[] = [];
  originatorId = ORIGINATOR_ID;
  constructor(
    private formProvider: TransactionFormProvider,
    public activeModal: NgbActiveModal,
    private http: HttpService,
    private commonService: CommonService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.transactionUpdateForm = this.formProvider.initForm();
    this.subscribeToCredebtorChange();
    if (this.transactionType) {
      this.updateTransactionType();
    }
  }

  updateTransactionType(){
    this.transactionUpdateForm.get('transactionType')?.setValue(this.transactionType)
  }
  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.params = params;
    this.getReferenceData();
  }

  private getReferenceData(): void {
    this.gridApi.showLoadingOverlay();
    this.rowData = Reference_Data;
    this.updateSno();
  }


  onSubmit() {
    const fieldsToFormat = ['date'];
    this.isSubmitted = true;
    if (this.transactionUpdateForm.invalid) {
      return;
    }
    let rawData = this.transactionUpdateForm.value;
    rawData = getFormData(rawData);
    fieldsToFormat.forEach((field) => {
      const date = rawData[field];
      if (date) {
        rawData[field] = field === 'months' ? moment(date).format('MM') : moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
      }
    });
    rawData.originatorId = this.originatorId;
    // this.doCreate(rawData);
  }

  onSelect(event: any): void {
    this.isSubmitted = false;
  }

  doCreate(data: any): void {
    this.http.postData('transactionUpdate', data).subscribe({
      next: (response: any) => {
        this.closeModal();
        this.transactionUpdateForm.reset();
        Swal.fire({
          icon: 'success',
          text: 'Transaction successfully Updated.',
          showConfirmButton: false,
          timer: 5000,
        });
      },
      error: (error) => {
        Swal.fire({
          icon: 'error',
          text: error.error.details || error.error.message,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
      },
    });
  }

  updateSno() {
    const result = this.commonService.updateSno(this.rowData, 0, null);
    this.rowData = [...result];
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  private subscribeToCredebtorChange(): void {
    this.transactionUpdateForm
      .get('credebtor')
      ?.valueChanges.pipe(debounceTime(300))
      .subscribe((value) => {
        if (this.transactionUpdateForm.get('credebtor')?.value && this.transactionUpdateForm.get('credebtor')?.value == 'Reconcile a Creditor Account') {
          this.balance = 4300.29;
        }
        else {
          this.balance = 4300.29;
        }
      });

  }

  handleCellClick(event: any): void {
    const bal = this.balance
    this.balance = Math.round((event.data.amount - bal) * 100) / 100;
  }

}
