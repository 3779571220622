<div 
     class="modal fade show custom_popup"
     tabindex="-1"
     role="dialog"
>
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header justify-content-between">
                <h5 class="modal-title">
              Add New User
                </h5>
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        (click)="closeModal()"
                >
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body padding-model form-editing">
              
<div class="row">
    <div class="col-lg-12">
        <div class="card w-100">
            <div class="card">
                <form
                    *ngIf="userForm"
                    [formGroup]="userForm"
                    (ngSubmit)="onSubmit()"
                >
                    <div class="row">

                        <div style="width: 110px;" class="field-groups">
                            <div class="mt-3">
                              <label for="firstName" class="form-title"
                                > First Name
                                <span
                                  class="tooltip-ng ms-0"
                                  placement="bottom"
                                  ngbTooltip="{{ tooltipData.firstName }}"
                                  ><i class="bi bi-question-circle"></i></span
                              ></label>
                              <input
                                type="text"
                                class="form-control sd-width"
                                placeholder=""
                                formControlName="firstName"
                                id="firstName"
                              />
                            </div>
                            <app-form-error-component [control]="userForm.controls['firstName']" />
                          </div>
    
                          <div style="width: 110px;" class="field-groups">
                            <div class="mt-3">
                              <label for="lastName" class="form-title"
                                > Last Name
                                <span
                                  class="tooltip-ng ms-0"
                                  placement="bottom"
                                  ngbTooltip="{{ tooltipData.lastName }}"
                                  ><i class="bi bi-question-circle"></i></span
                              ></label>
                              <input
                                type="text"
                                class="form-control sd-width"
                                placeholder=""
                                formControlName="lastName"
                                id="lastName"
                              />
                            </div>
                            <app-form-error-component [control]="userForm.controls['lastName']" />
                          </div>
                          <div style="width: 143px;" class="field-groups">
                            <div class="mt-3">
                              <label for="email" class="form-title"
                                > Email
                                <span
                                  class="tooltip-ng ms-0"
                                  placement="bottom"
                                  ngbTooltip="{{ tooltipData.email }}"
                                  ><i class="bi bi-question-circle"></i></span
                              ></label>
                              <input
                                type="text"
                                class="form-control sd-width"
                                placeholder=""
                                formControlName="email"
                                id="email"
                              />
                            </div>
                            <app-form-error-component [control]="userForm.controls['email']" />
                          </div>

                          <div style="width: 219px;" class="field-groups">
                            <div class="mt-3">
                              <label for="organisationName" class="form-title"
                                > Organisation Name
                                <span
                                  class="tooltip-ng ms-0"
                                  placement="bottom"
                                  ngbTooltip="{{ tooltipData.organisationName }}"
                                  ><i class="bi bi-question-circle"></i></span
                              ></label>
                              <input
                                type="text"
                                class="form-control sd-width"
                                placeholder=""
                                formControlName="organisationName"
                                id="organisationName"
                                [readonly]="true"
                              />
                            </div>
                            <app-form-error-component [control]="userForm.controls['organisationName']" />
                          </div> 


                        <div class="col-12 bottom-fixed">
                            <hr />
                            <div class="d-flex justify-content-center mt-3">
                                <button [disabled]="userForm.invalid" type="submit" class="btn btn-light me-3 btn-custom-footer save_btn">
                                    Save
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-light btn-custom-footer cancel_btn"
                                    data-dismiss="modal"
                                    (click)="closeModal()"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
            </div>
        </div>
    </div>
</div>
