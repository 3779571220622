<div class="ag-paging-panel" id="ag-700">
  <!--AG-PAGE-SIZE-SELECTOR-->
  <span class="ag-paging-page-size" ref="pageSizeComp">
    <div class="ag-picker-field ag-labeled ag-label-align-left ag-select" role="presentation">
      <div ref="eLabel" class="ag-label" aria-hidden="false" id="ag-829-label">Page Size:</div>
      <div
        ref="eWrapper"
        class="ag-wrapper ag-picker-field-wrapper ag-picker-collapsed"
        tabindex="0"
        aria-expanded="false"
        role="combobox"
        aria-controls="ag-select-list-830"
        aria-label="Page Size"
      >
        <div ref="eDisplayField" class="ag-picker-field-display" id="ag-829-display">100</div>
        <div ref="eIcon" class="ag-picker-field-icon" aria-hidden="true">
          <span class="ag-icon ag-icon-small-down" unselectable="on" role="presentation"></span>
        </div>
      </div>
    </div>
  </span>

  <span class="ag-paging-row-summary-panel" role="status">
    <span
      *ngIf="currentPageIndex === 0"
      id="ag-700-first-row"
      ref="lbFirstRowOnPage"
      class="ag-paging-row-summary-panel-number"
    >
      1
    </span>
    <span
      *ngIf="currentPageIndex > 0"
      id="ag-700-first-row"
      ref="lbFirstRowOnPage"
      class="ag-paging-row-summary-panel-number"
    >
      {{ currentPageIndex * paginationPageSize + 1 | numberValueFormater: 0 }}
    </span>
    <span id="ag-700-to"> to </span>
    <span id="ag-700-last-row" ref="lbLastRowOnPage" class="ag-paging-row-summary-panel-number">
      {{ paginationPageSize * (currentPageIndex + 1) | numberValueFormater: 0 }}
    </span>
    <span id="ag-700-of"> of </span>
    <span id="ag-700-row-count" ref="lbRecordCount" class="ag-paging-row-summary-panel-number">
      {{ totalRecordsDisplay }}
    </span>
  </span>
  <span class="ag-paging-page-summary-panel" role="presentation">
    <a *ngIf="currentPageIndex > 0" type="button" class="custom-page-btn"
      ><img
        alt=""
        src="../../../../assets/images/icon/first.svg"
        width="16px"
        (click)="pageChange.emit(0); $event.preventDefault()"
    /></a>
    <a *ngIf="currentPageIndex === 0" type="button" class="custom-page-btn page-btn-disabled"
      ><img alt="" src="../../../../assets/images/icon/first.svg" width="16px"
    /></a>
    <a *ngIf="currentPageIndex > 0" type="button" class="custom-page-btn"
      ><img
        alt=""
        src="../../../../assets/images/icon/previous.svg"
        width="16px"
        (click)="pageChange.emit(currentPageIndex - 1)"
    /></a>
    <a *ngIf="currentPageIndex === 0" type="button" class="custom-page-btn page-btn-disabled"
      ><img alt="" src="../../../../assets/images/icon/previous.svg" width="16px"
    /></a>

    <span class="ag-paging-description" role="status">
      <span id="ag-700-start-page"> Page </span>
      <span id="ag-700-start-page-number" ref="lbCurrent" class="ag-paging-number">
        {{ currentPageIndex + 1 | numberValueFormater: 0 }}
      </span>
      <span id="ag-700-of-page"> of </span>
      <span id="ag-700-of-page-number" ref="lbTotal" class="ag-paging-number">
        {{ totalPageString }}
      </span>
    </span>
    <a *ngIf="currentPageIndex + 1 < totalPage" type="button" class="custom-page-btn"
      ><img
        alt=""
        src="../../../../assets/images/icon/next.svg"
        width="16px"
        alt="download"
        (click)="pageChange.emit(currentPageIndex + 1)"
    /></a>

    <a
      *ngIf="currentPageIndex + 1 === totalPage || totalPage === 0"
      type="button"
      class="custom-page-btn page-btn-disabled"
      ><img alt="" src="../../../../assets/images/icon/next.svg" width="16px"
    /></a>

    <a *ngIf="currentPageIndex + 1 < totalPage" type="button" class="custom-page-btn"
      ><img
        alt=""
        src="../../../../assets/images/icon/last.svg"
        width="16px"
        (click)="pageChange.emit(totalPage - 1)"
    /></a>

    <a
      *ngIf="currentPageIndex + 1 === totalPage || totalPage === 0"
      type="button"
      class="custom-page-btn page-btn-disabled"
      ><img alt="" src="../../../../assets/images/icon/last.svg" width="16px"
    /></a>
  </span>
</div>
