import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-form-error-component',
  templateUrl: './form-error-component.component.html',
  styleUrls: ['./form-error-component.component.scss'],
})
export class FormErrorComponentComponent {
  @Input() control: AbstractControl;
}
