import * as moment from 'moment';

export const omitKey = (object: { [k: string]: any }, key: string | number): { [k: string]: any } => {
  return ({ [key]: key, ...object } = object) && object;
};

export const ParseDate = (param: string): { year: number; day: number; month: number } => {
  return {
    year: parseInt(moment(param).format('YYYY'), 10),
    day: parseInt(moment(param).format('DD'), 10),
    month: parseInt(moment(param).format('M'), 10),
  };
};

export const getFormData = (formValues: Record<string, unknown>, allowEmpty?: boolean): Record<string, any> => {
  return Object.keys(formValues).reduce((data: any, field) => {
    // Trim the string values;
    if (typeof formValues[field] === 'string') formValues[field] = (formValues[field] as string).trim();

    if (typeof formValues[field] === 'boolean') formValues[field] = formValues[field];

    // transform Date object to formatted date string
    if (formValues[field] instanceof Date) formValues[field] = parseDate(formValues[field] as Date);

    // adding value to data if value is not false or empty and empty is allowed
    if (formValues[field] || (allowEmpty && formValues[field] === '')) data[field] = formValues[field];
    return data;
  }, {});
};

export const parseDate = (param: any): any  => {
    const dateMoment = moment(`${param.year}-${param.month}-${param.day}`, 'YYYY-MM-DD');
   return moment(dateMoment).format('YYYY-MM-DD');
}

/**
 * Get difference values as object from comparision of the 2 object arguments
 * @param {object} formValues
 * @param {object} snapshot
 */
export const getModelChanges = (formValues: Record<string, unknown>, snapshot: Record<string, any>): Record<string, any> => {
    return Object.keys(formValues).reduce((changes, field) => {
        if (typeof formValues[field] === 'string') formValues[field] = (formValues[field] as string).trim();
        return (formValues[field] != snapshot[field]) ? {...changes, [field]: formValues[field]} : changes;
    }, {});
};

export const DateFilterParamsSettings = (dateFilter: Record<string, string>, key: string): any => {
    let queryParams: Record<string, string> = {};
    if (dateFilter['type'] === 'inRange') {
        queryParams[`${[key]}[from]`] = moment(dateFilter['dateFrom']).format('YYYY-MM-DD');
        queryParams[`${[key]}[to]`] = moment(dateFilter['dateTo']).format('YYYY-MM-DD');
        queryParams[`${[key]}[comparator]`] = 'Between';
    } else if (dateFilter['type'] === 'after') {
        queryParams[`${[key]}[from]`] = moment(dateFilter['dateFrom']).format('YYYY-MM-DD');
        queryParams[`${[key]}[comparator]`] = dateFilter['type'].charAt(0).toUpperCase() + dateFilter['type'].slice(1);
    } else {
        queryParams[`${[key]}[from]`] = moment(dateFilter?.['dateFrom']).format('YYYY-MM-DD');
        queryParams[`${[key]}[comparator]`] = dateFilter['type'].charAt(0).toUpperCase() + dateFilter['type'].slice(1);
    }

    return queryParams;
}


